import { ActList, ActResponse, CalcData } from 'types';
import { ExecutionState } from './CalculationСomplicated.types';
import { createContext, Dispatch, SetStateAction } from 'react';

interface IActListContext {
  data?: ActResponse;
  calculation?: CalcData;
  current: ActList | null;
  setCurrent?: (act: ActList | null) => void;
  getTable?: (
    id: number,
    depth?: number,
    page?: number,
    shouldClearState?: boolean
  ) => void;
  table?: ExecutionState;
  setExecutionState?: Dispatch<SetStateAction<ExecutionState>>;
  isFetching?: boolean;
  refetchActs?: () => void;
  selectedRowsIds?: number[];
  setSelectedRowsIds?: Dispatch<SetStateAction<number[]>>;
}

export const ActListContext = createContext<IActListContext>({
  data: undefined,
  current: null
});

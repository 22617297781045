import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { CircularProgress } from '@mui/material';
import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  RowClassParams
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
  calculationsApi,
  useLazyGetComplicatedRowsListPreviewQuery
} from 'api/calculations';
import { ProjectLabel } from 'components/ProjectLabel';
import { usePagination } from 'hooks/use-pagination';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useCalcId } from 'hooks/useCalcId';
import { useProjectId } from 'hooks/useProjectId';
import {
  HeaderComponentWithFilter,
  IndexMethodCalculationDialogFilter
} from 'pages/projects-calculation/index-method-calculation';
import { useLocation } from 'react-router-dom';
import { setGridRef } from 'store/slices/ag-grid/ag-grid-slice';
import {
  appendData,
  getChildren,
  getData
} from 'store/slices/calculations/bim/actions';
import {
  filterByID,
  getBimCalcState,
  initList,
  setHiddenRows
} from 'store/slices/calculations/bim/bim.slice';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { CalcData } from 'types';
import {
  GetRowsListFilterParams,
  IComplicatedIndexMethod
} from '../../../../../../api/calculations/types';
import { CalculationLabel } from '../../../../../../components/CalculationLabel';
import Progress from '../../../../../../components/Progress';
import {
  ENUMLocalStorage,
  useLocalStorage
} from '../../../../../../hooks/use-local-storage';
import { useStepperContext } from '../../../../../../hooks/useStepper';
import { modifyNum } from '../../../../../../utils/getFormatNumber';
import {
  PageStyled,
  WrapperAgGrid
} from '../../../Accomplishment/Accomplishment.styles';
import Cell from '../../../Accomplishment/components/Cell';
import { modifyData } from '../../../Accomplishment/helper';
import { ActListContext, BimStepper } from '../..';
import LevelExecution from './components/LevelExecution';
import { RowCheckbox } from './components/RowCheckox/RowCheckox';
import { useDeleteEstimateInCalculation } from 'hooks/useDeleteEstimateInCalculation';
import { DeleteProcessModal } from 'pages/Calculations/components/DeleteProcessModal';
import { NoRows } from 'pages/Administration/AdminReferences/Prices/useTableData';

export type EstimateComplicatedProps = {
  calculation?: CalcData;
};

interface IAgGridTable {
  collapseFn: (id: number, index: number) => void;
  hiddenRowsIds: Set<number>;
  total: IComplicatedIndexMethod | undefined;
}
const gridOption = {
  navigateToNextHeader: () => null,
  tabToNextHeader: () => null,
  components: {
    headerWithFilters: HeaderComponentWithFilter
  }
};
const LIMIT = 10000;
export const EstimateComplicated = () => {
  // const edit = useLocation().state?.edit ?? true;
  const dispatch = useAppDispatch();

  const { calculation, getTable } = useContext(ActListContext);

  const {
    list,
    total,
    loader,
    endFetch,
    page,
    hiddenRows: hiddenRowsIds,
    isNotFetched
  } = useTypedSelector(getBimCalcState);

  console.log('hiddenRowsIds', hiddenRowsIds);

  const firstLoad = useRef(true);

  const projectID = useProjectId();
  const calcID = useCalcId();
  const params = new URLSearchParams(useLocation().search);
  const isPreview = params.get('state') === 'preview';
  const [filterDrawer, setFilterDrawer] = useState<
    keyof GetRowsListFilterParams | null
  >(null);

  const [table, setTable] = React.useState<
    undefined | IComplicatedIndexMethod[]
  >([]);

  const { setValue } = useLocalStorage(
    ENUMLocalStorage.levelsBaseMethodCalc,
    []
  );
  const Ref = useRef<AgGridReact<IComplicatedIndexMethod> | null>(null);

  const [
    getServerDataPreview,
    { data: serverDataPreview, isLoading: serverIsLoadingPreview }
  ] = useLazyGetComplicatedRowsListPreviewQuery();
  const [bodyParams, setBodyParams] = React.useState<
    Required<GetRowsListFilterParams>
  >({
    chapter: [],
    code: [],
    header: [],
    lsr: [],
    title: [],
    unit: []
  });
  useLayoutEffect(() => {
    if (isPreview) {
      getServerDataPreview({
        id: calculation?.id ?? calcID,
        projectID,
        isPreview,
        body: bodyParams
      });
    }
  }, [calcID, calculation?.id, getData, isPreview, projectID, bodyParams]);

  useEffect(() => {
    if (!table || table.length === 0) {
      if (isPreview) {
        setTable(serverDataPreview?.data);
        const collapsedRows = new Set<number>();
        serverDataPreview?.data.forEach((row) => {
          if (row.level!) {
            collapsedRows.add(row.id);
          }
        });
        dispatch(setHiddenRows(collapsedRows));
      } else {
        setTable(list);
      }
    }
  }, [list, table, serverDataPreview, isPreview]);

  const { setMaxDepth, depth, maxDepth, toggleDepth } =
    useStepperContext<BimStepper>(function () {}, 'calculation');
  useLayoutEffect(() => {
    setMaxDepth('calculation', 3);
  }, []);
  /***************************** Complicated List ****************************************/

  const onChangeParams = React.useCallback(
    (key: keyof GetRowsListFilterParams, target: string | string[]) => {
      setBodyParams((prevState) => {
        const newState: typeof prevState = {
          ...prevState
        };
        if (typeof target === 'string') {
          newState[key] = newState[key].includes(target)
            ? newState[key].filter((el) => el !== target)
            : newState[key].concat(target);
        } else if (Array.isArray(target)) {
          newState[key] = target;
        }
        return newState;
      });
    },
    []
  );

  const [deletedRowId, setDeletedRowId] = useState<number | null>(null);
  const {
    ConfirmDialogDelete,
    openConfirmDelete,
    ConfirmDialogDeleteRowsWithExecution,
    deleteResponse
  } = useDeleteEstimateInCalculation({
    deletedIds: deletedRowId ? [deletedRowId] : [],
    deletedDataType: 'estimate',
    onDelete: () => {
      toggleDepth('calculation', 0);
      dispatch(
        calculationsApi.util.invalidateTags([
          'BimChildren',
          'CalculationEstimatesById'
        ])
      );
      dispatch(setHiddenRows(new Set()));

      getTable?.(Number(calcID), 0, 0, true);
    }
  });

  const handleEstimateDeleteClick = (id: number) => {
    setDeletedRowId(id);
    openConfirmDelete();
  };

  /****************************************************************************************/

  const getRowClass = (params: RowClassParams<IComplicatedIndexMethod>) => {
    const colors = {
      inactive: '#B8B8B8',
      deleted: '#B8B8B8',
      dont_belong: '#FF0101',
      awaiting_deletion: '#B8B8B8'
    };
    const styles: React.CSSProperties = {};

    const classCell = [];
    if (params.node.data?.id === -10000) {
      classCell.push('loader-row');
    }
    if (params.node.data?.type === 'file') {
      classCell.push('level-1');
    } else if (params.node.data?.type === 'level') {
      classCell.push('rate');
    } else if (params.node.data?.type === 'sum') {
      classCell.push('rate-position');
    } else {
      classCell.push('cell-bg-def');
    }
    if (params.node.data?.subtype) {
      classCell.push(params.node.data.subtype + '-cell');
      styles.color = colors[params.node.data.subtype];
    }

    return classCell;
  };
  const activeRequests = useRef(false);
  const collapse = useCallback(
    async (id: number, rowIndex: number) => {
      // dispatch(toggleCollapseMod('mixed'));

      if (loader || activeRequests.current) return;
      activeRequests.current = true;
      const newSet = new Set(hiddenRowsIds);

      if (!hiddenRowsIds.has(id)) {
        newSet.add(id);
        if (isPreview) {
          const children = serverDataPreview?.data.filter(
            (el) => el.parentID === id
          );
          Ref.current?.api.applyTransaction({
            addIndex: rowIndex + 1,
            add: children
          });
          dispatch(setHiddenRows(newSet));
        } else {
          dispatch(setHiddenRows(newSet));
          dispatch(
            getChildren({
              calcID,
              rowID: id,
              index: rowIndex,
              action: !hiddenRowsIds.has(id) ? 'add' : 'remove'
            })
          );
        }
      } else {
        newSet.delete(id);
        const remove: IComplicatedIndexMethod[] = [];
        const parentsSet = new Set<number>();
        Ref.current?.api?.forEachNode((n) => {
          // if (n.data?.id! === id) {
          //   remove.push(n.data!);
          // }
          if (n.data?.parentID! === id || parentsSet.has(n.data?.parentID!)) {
            remove.push(n.data!);
            parentsSet.add(n.data?.id!);
            newSet.delete(n.data?.id!);
          }
        });
        dispatch(setHiddenRows(newSet));
        Ref.current?.api.applyTransaction({ remove });
        dispatch(filterByID(parentsSet));
      }
      dispatch(setHiddenRows(newSet));
      activeRequests.current = false;
    },
    [calcID, dispatch, hiddenRowsIds, loader, activeRequests.current]
  );
  // const debounceCollapse = useMemo(() => debounce(collapse, 500), [collapse]);

  function ceilData(
    mod: 'base' | 'curr',
    key: keyof IComplicatedIndexMethod['base'],
    data?: IComplicatedIndexMethod
  ) {
    const field = data?.[mod]?.[key];
    if (data?.type === 'file' || data?.type === 'sum') {
      if (typeof field === 'number') {
        return modifyData({ data: Math.round(field) });
      }
    }
    return modifyData({ data: field as number | null });
  }

  const generateColumns = useCallback(
    ({
      collapseFn,
      hiddenRowsIds,
      total
    }: IAgGridTable): (ColDef<any, any> | ColGroupDef<any>)[] => {
      if (!total) return [];
      let empty = true;

      let foundTotalKey: any;

      for (foundTotalKey in total.curr!) {
        if (
          !Array.isArray(total.curr![foundTotalKey as never]) &&
          foundTotalKey !== 'dynamicRows'
        ) {
          if (total.curr![foundTotalKey as never] !== null) {
            empty = false;
          }
        }
      }
      return [
        {
          pinned: 'left',
          lockPinned: true,
          lockPosition: true,
          resizable: false,
          suppressMovable: true,
          width: 60,
          minWidth: 60,
          headerClass: 'header-complicated',
          headerName: '',

          cellRenderer: (
            params: ICellRendererParams<IComplicatedIndexMethod, any, any>
          ) => {
            return isPreview ? <></> : <RowCheckbox {...params} />;
          }
        },
        {
          field: 'level',
          pinned: 'left',
          resizable: false,
          lockPinned: true,
          suppressMovable: true,
          width: 100,
          // maxWidth: 100,
          minWidth: 100,
          cellStyle: {
            // width: '68px',
            padding: '0 10px'
          },
          colSpan: (params) => {
            // return params.node?.rowPinned === 'top' ? 7 : 1;
            return params.data?.type === 'sum' ? 7 : 1;
          },

          headerName: 'Ур',

          cellRenderer: (
            params: ICellRendererParams<IComplicatedIndexMethod, any, any>
          ) => {
            return (
              <LevelExecution
                {...params}
                collapse={collapseFn}
                hiddenArr={hiddenRowsIds}
                onDelete={handleEstimateDeleteClick}
              />
            );
          }
        },
        {
          field: 'number',
          pinned: 'left',
          // lockPinned: true,
          width: 50,
          // suppressMovable: false,
          // maxWidth: 50,
          lockPinned: false,
          minWidth: 50,
          headerClass: 'header-complicated',
          headerName: '№ п\\п',

          cellRenderer: ({
            data
          }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
            return <Cell float={'center'}>{data?.number}</Cell>;
          }
        },
        {
          field: 'lsr',
          pinned: 'left',
          // lockPinned: true,
          lockPinned: false,
          width: 120,
          // headerComponent:'headerWithFilters',
          // suppressMovable: false,
          // maxWidth: 120,
          minWidth: 120,
          headerClass: 'header-complicated',
          headerName: 'Шифр ЛСР',
          cellRenderer: ({
            data
          }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
            return <Cell>{data?.lsr}</Cell>;
          }
        },
        {
          field: 'chapter',
          lockPinned: false,
          pinned: 'left',
          // lockPinned: true,
          width: 165,
          // headerComponent:'headerWithFilters',
          // suppressMovable: false,
          // maxWidth: 165,
          minWidth: 165,
          headerClass: 'header-complicated',
          headerName: 'Раздел',
          cellRenderer: ({
            data
          }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
            return <Cell>{data?.chapter}</Cell>;
          }
        },
        {
          field: 'header',
          lockPinned: false,
          pinned: 'left',
          // lockPinned: true,
          width: 110,
          // headerComponent:'headerWithFilters',
          // suppressMovable: false,
          // maxWidth: 110,
          minWidth: 110,
          headerClass: 'header-complicated',
          headerName: 'Заголовок',
          cellRenderer: ({
            data
          }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
            return <Cell>{data?.header}</Cell>;
          }
        },
        {
          field: 'code',
          lockPinned: false,
          pinned: 'left',
          // lockPinned: true,
          width: 235,
          // headerComponent:'headerWithFilters',
          // suppressMovable: false,
          // maxWidth: 235,
          minWidth: 235,
          headerClass: 'header-complicated',
          headerName: 'Код расценки',
          cellRenderer: ({
            data
          }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
            return <Cell float={'center'}>{data?.code}</Cell>;
          }
        },
        {
          field: 'title',
          lockPinned: false,
          pinned: 'left',
          // lockPinned: true,
          width: 400,
          // headerComponent:'headerWithFilters',
          // maxWidth: 400,
          minWidth: 400,
          headerClass: 'header-complicated',
          headerName: 'Наименование',
          cellRenderer: ({
            data
          }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
            return <Cell>{data?.title}</Cell>;
          }
        },

        {
          field: 'unit',
          width: 120,
          // pinned: 'left',
          // // lockPinned: true,
          // headerComponent:'headerWithFilters',
          // suppressMovable: true,
          // maxWidth: 120,
          lockPinned: false,
          minWidth: 120,
          headerClass: 'header-complicated',
          headerName: 'Ед. изм.',
          cellRenderer: ({
            data
          }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
            return <Cell float={'center'}>{data?.unit}</Cell>;
          }
        },
        {
          field: 'quantity',
          width: 120,
          lockPinned: false,
          // suppressMovable: true,
          // maxWidth: 120,
          minWidth: 120,
          headerClass: 'header-complicated',
          headerName: 'Кол-во.',
          cellRenderer: ({
            data
          }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
            return (
              <Cell float={'center'}>
                {data?.quantity?.toString()?.replace(/\./g, ',')}
              </Cell>
            );
            // return <Cell float={'center'}>{modifyData({ data: data?.quantity! })}</Cell>;
          }
        },
        {
          field: 'base',
          width: 120,
          // suppressMovable: false,
          // maxWidth: 120,

          suppressMovable: true,
          marryChildren: true,
          minWidth: 120,
          headerName: 'В базовых ценах',
          // },
          children: [
            {
              field: 'materialsPriceBase',
              width: 205,
              // suppressMovable: true,
              // maxWidth: 205,
              minWidth: 205,
              headerClass: 'header-complicated',
              headerName: 'МТР',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell
                    originData={modifyNum(data?.base?.materialsPrice!)}
                    isNum
                    float={'right'}>
                    {ceilData('base', 'materialsPrice', data)}
                  </Cell>
                );
              }
            },
            {
              field: 'equipmentPriceBase',
              width: 205,
              // suppressMovable: true,
              hide: !total?.base?.equipmentPrice,
              // maxWidth: 205,
              minWidth: 205,
              headerClass: 'header-complicated',
              headerName: 'Оборудование',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell
                    originData={modifyNum(data?.base?.equipmentPrice!)}
                    isNum
                    float={'right'}>
                    {ceilData('base', 'equipmentPrice', data)}
                    {/* {modifyData({ data: data?.base?.equipmentPrice! })} */}
                  </Cell>
                );
              }
            },
            {
              field: 'constructionEffortBase',
              width: 243,
              // suppressMovable: true,
              // maxWidth: 243,
              minWidth: 243,
              headerClass: 'header-complicated',
              headerName: 'ТЗ ОР, чел-ч',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell
                    originData={modifyNum(data?.base?.constructionEffort!)}
                    isNum
                    float={'right'}>
                    {/* {ceilData('base', 'constructionEffort', data)} */}
                    {modifyData({ data: data?.base?.constructionEffort! })}
                  </Cell>
                );
              }
            },
            {
              field: 'workersSalaryBase',
              width: 180,
              // suppressMovable: true,
              // maxWidth: 180,
              minWidth: 180,
              headerClass: 'header-complicated',
              headerName: 'ФОТ ОР',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell
                    originData={modifyNum(data?.base?.workersSalary!)}
                    isNum
                    float={'right'}>
                    {ceilData('base', 'workersSalary', data)}
                    {/* {modifyData({ data: data?.base?.workersSalary! })} */}
                  </Cell>
                );
              }
            },
            {
              field: 'mechanicsEffortBase',
              width: 230,
              // suppressMovable: true,
              // maxWidth: 230,
              minWidth: 230,
              headerClass: 'header-complicated',
              headerName: 'ТЗМ, чел-ч',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell
                    originData={modifyNum(data?.base?.mechanicsEffort)}
                    isNum
                    float={'right'}>
                    {ceilData('base', 'mechanicsEffort', data)}
                    {/* {modifyData({ data: data?.base?.mechanicsEffort! })} */}
                  </Cell>
                );
              }
            },
            {
              field: '',
              // suppressMovable: true,
              headerName: 'МИМ',
              marryChildren: true,
              lockPinned: true,
              children: [
                {
                  field: 'mimExploitationBase',
                  width: 136,
                  // suppressMovable: true,
                  // maxWidth: 136,
                  lockPinned: true,
                  minWidth: 136,
                  headerClass: 'header-complicated',
                  headerName: 'Всего',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    IComplicatedIndexMethod,
                    any,
                    any
                  >) => {
                    return (
                      <Cell
                        originData={modifyNum(data?.base?.mimExploitation)}
                        isNum
                        float={'right'}>
                        {ceilData('base', 'mimExploitation', data)}
                        {/* {modifyData({ data: data?.base?.mimExploitation! })} */}
                      </Cell>
                    );
                  }
                },
                {
                  field: 'mechanicsSalaryBase',
                  width: 330,
                  // suppressMovable: true,
                  // maxWidth: 330,
                  minWidth: 330,
                  lockPinned: true,
                  headerClass: 'header-complicated',
                  headerName: 'в т.ч. ФОТ мех.',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    IComplicatedIndexMethod,
                    any,
                    any
                  >) => {
                    return (
                      <Cell
                        originData={modifyNum(data?.base?.mechanicsSalary!)}
                        isNum
                        float={'right'}>
                        {ceilData('base', 'mechanicsSalary', data)}
                        {/* {modifyData({ data: data?.base?.mechanicsSalary! })} */}
                      </Cell>
                    );
                  }
                }
              ]
            },
            {
              field: 'hpBase',
              width: 105,
              // suppressMovable: true,
              // maxWidth: 105,
              minWidth: 105,
              headerClass: 'header-complicated',
              headerName: 'НР, %',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                const renderPercent =
                  data?.type !== 'file'
                    ? modifyData({ data: data?.base.hp!, replace: true })
                    : null;
                return (
                  <Cell
                    originData={modifyNum(data?.base.hp)}
                    isNum
                    float={'right'}>
                    {renderPercent ? renderPercent + '%' : null}
                  </Cell>
                );
              }
            },
            {
              field: 'overheadsBase',
              width: 190,
              // suppressMovable: true,
              // maxWidth: 190,
              minWidth: 190,
              headerClass: 'header-complicated',
              headerName: 'НР',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell
                    originData={modifyNum(data?.base?.overheads)}
                    isNum
                    float={'right'}>
                    {ceilData('base', 'overheads', data)}
                    {/* {modifyData({ data: data?.base?.overheads! })} */}
                  </Cell>
                );
              }
            },
            {
              field: 'spBase',
              width: 105,
              // suppressMovable: true,
              // maxWidth: 105,
              minWidth: 105,
              headerClass: 'header-complicated',
              headerName: 'СП, %',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                const renderPercent =
                  data?.type !== 'file'
                    ? modifyData({ data: data?.base.sp!, replace: true })
                    : null;
                return (
                  <Cell
                    originData={modifyNum(data?.base.sp)}
                    isNum
                    float={'right'}>
                    {renderPercent ? renderPercent + '%' : null}
                  </Cell>
                );
              }
            },
            {
              field: 'estimatedProfitBase',
              width: 190,
              // suppressMovable: true,
              // maxWidth: 190,
              minWidth: 190,
              headerClass: 'header-complicated',
              headerName: 'СП',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell
                    originData={modifyNum(data?.base?.estimatedProfit)}
                    isNum
                    float={'right'}>
                    {ceilData('base', 'estimatedProfit', data)}
                    {/* {modifyData({ data: data?.base?.estimatedProfit! })} */}
                  </Cell>
                );
              }
            },
            {
              field: 'totalWorkCostBase',
              hide: !total?.base?.dynamicRows?.length,
              width: 190,
              // suppressMovable: true,
              // maxWidth: 190,
              minWidth: 190,
              headerClass:
                'header-top-border header-left-border header-right-border header-complicated',
              cellClass: 'header-left-border header-right-border',
              headerName: 'Итого',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell
                    originData={modifyNum(data?.base?.totalWorkCost)}
                    isNum
                    float={'right'}>
                    {ceilData('base', 'totalWorkCost', data)}
                    {/* {modifyData({ data: data?.base?.totalWorkCost! })} */}
                  </Cell>
                );
              }
            },
            ...(!total?.base?.dynamicRows?.length
              ? ([] as any)
              : total.base.dynamicRows.map((dyn, idxDyn) => {
                  return {
                    field: dyn.title + 'Base',
                    width: 190,
                    // suppressMovable: true,
                    // maxWidth: 190,
                    minWidth: 190,
                    headerClass: 'header-complicated',
                    headerName: dyn.title,
                    cellRenderer: ({
                      data
                    }: ICellRendererParams<
                      IComplicatedIndexMethod,
                      any,
                      any
                    >) => {
                      return (
                        <Cell
                          originData={modifyNum(
                            data?.base?.dynamicRows?.[idxDyn]?.value
                          )}
                          isNum
                          float={'right'}>
                          {data?.base?.dynamicRows?.[idxDyn]?.value
                            ? modifyData({
                                data: Math.round(
                                  data?.base?.dynamicRows?.[idxDyn]?.value!
                                )
                              })
                            : null}
                          {/* {modifyData({
                            data: data?.base?.dynamicRows?.[idxDyn]?.value!
                          })} */}
                        </Cell>
                      );
                    }
                  };
                })),
            {
              field: 'workCostInRublesBase',
              width: 190,
              // suppressMovable: true,
              // maxWidth: 190,
              minWidth: 190,
              headerClass:
                'header-top-border header-left-border header-right-border header-complicated',
              cellClass: 'header-left-border header-right-border',
              headerName: 'Всего стоимость',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell
                    originData={modifyNum(data?.base.workCostInRubles)}
                    isNum
                    float={'right'}>
                    {ceilData('base', 'workCostInRubles', data)}
                    {/* {modifyData({ data: data?.base.workCostInRubles! })} */}
                  </Cell>
                );
              }
            }
          ]
        },
        {
          field: 'currmaterialsPriceCurr',
          suppressMovable: true,
          hide: empty,
          // suppressMovable: false,
          headerName: 'В текущих ценах',
          marryChildren: true,
          children: [
            {
              field: 'materialsPriceCurr',
              hide: empty,
              width: 205,
              // suppressMovable: true,
              // maxWidth: 205,
              minWidth: 205,
              headerClass: 'header-complicated',
              headerName: 'МТР',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell
                    originData={modifyNum(data?.curr?.materialsPrice)}
                    isNum
                    float={'right'}>
                    {ceilData('curr', 'materialsPrice', data)}

                    {/* {modifyData({ data: data?.curr?.materialsPrice! })} */}
                  </Cell>
                );
              }
            },
            {
              field: 'equipmentPriceCurr',
              width: 205,
              // suppressMovable: true,
              hide: !total?.curr?.equipmentPrice || empty,
              // maxWidth: 205,
              minWidth: 205,
              headerClass: 'header-complicated',
              headerName: 'Оборудование',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell
                    originData={modifyNum(data?.curr?.equipmentPrice)}
                    isNum
                    float={'right'}>
                    {ceilData('curr', 'equipmentPrice', data)}
                    {/* {modifyData({ data: data?.curr?.equipmentPrice! })} */}
                  </Cell>
                );
              }
            },
            {
              field: 'constructionEffortCurr',
              width: 243,
              hide: empty,
              // suppressMovable: true,
              // maxWidth: 243,
              minWidth: 243,
              headerClass: 'header-complicated',
              headerName: 'ТЗ ОР, чел-ч',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell
                    originData={modifyNum(data?.curr?.constructionEffort)}
                    isNum
                    float={'right'}>
                    {modifyData({
                      data: data?.curr?.constructionEffort!,
                      replace: true
                    })}
                  </Cell>
                );
              }
            },
            {
              field: 'workersSalaryCurr',
              width: 180,
              hide: empty,

              // suppressMovable: true,
              // maxWidth: 180,
              minWidth: 180,
              headerClass: 'header-complicated',
              headerName: 'ФОТ ОР',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell
                    originData={modifyNum(data?.curr?.workersSalary)}
                    isNum
                    float={'right'}>
                    {ceilData('curr', 'workersSalary', data)}

                    {/* {modifyData({ data: data?.curr?.workersSalary! })} */}
                  </Cell>
                );
              }
            },
            {
              field: 'mechanicsEffortCurr',
              width: 230,
              hide: empty,
              // suppressMovable: true,
              // maxWidth: 230,
              minWidth: 230,
              headerClass: 'header-complicated',
              headerName: 'ТЗМ, чел-ч',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell
                    originData={modifyNum(data?.curr?.mechanicsEffort)}
                    isNum
                    float={'right'}>
                    {ceilData('curr', 'mechanicsEffort', data)}

                    {/* {modifyData({ data: data?.curr?.mechanicsEffort! })} */}
                  </Cell>
                );
              }
            },
            {
              field: '',
              hide: empty,
              // suppressMovable: true,
              headerName: 'МИМ',
              marryChildren: true,
              children: [
                {
                  field: 'mimExploitationCurr',
                  width: 136,
                  hide: empty,
                  // suppressMovable: true,
                  // maxWidth: 136,
                  minWidth: 136,
                  headerClass: 'header-complicated',
                  headerName: 'Всего',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    IComplicatedIndexMethod,
                    any,
                    any
                  >) => {
                    return (
                      <Cell
                        originData={modifyNum(data?.curr?.mimExploitation)}
                        isNum
                        float={'right'}>
                        {ceilData('curr', 'mimExploitation', data)}
                        {/* {modifyData({ data: data?.curr?.mimExploitation! })} */}
                      </Cell>
                    );
                  }
                },
                {
                  field: 'mechanicsSalaryCurr',
                  width: 330,
                  hide: empty,
                  // suppressMovable: true,
                  // maxWidth: 330,
                  minWidth: 330,
                  headerClass: 'header-complicated',
                  headerName: 'в т.ч. ФОТ мех.',
                  cellRenderer: ({
                    data
                  }: ICellRendererParams<
                    IComplicatedIndexMethod,
                    any,
                    any
                  >) => {
                    return (
                      <Cell
                        originData={modifyNum(data?.curr?.mechanicsSalary)}
                        isNum
                        float={'right'}>
                        {ceilData('curr', 'mechanicsSalary', data)}
                        {/* {modifyData({ data: data?.curr?.mechanicsSalary! })} */}
                      </Cell>
                    );
                  }
                }
              ]
            },
            {
              field: 'hpCurr',
              width: 105,
              hide: empty,
              // suppressMovable: true,
              // maxWidth: 105,
              minWidth: 105,
              headerClass: 'header-complicated',
              headerName: 'НР, %',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                const renderPercent =
                  data?.type !== 'file'
                    ? modifyData({ data: data?.curr?.hp!, replace: true })
                    : null;
                return (
                  <Cell
                    originData={modifyNum(data?.curr?.hp)}
                    isNum
                    float={'right'}>
                    {renderPercent ? renderPercent + '%' : null}
                  </Cell>
                );
              }
            },
            {
              field: 'overheadsCurr',
              width: 190,
              hide: empty,
              // suppressMovable: true,
              // maxWidth: 190,
              minWidth: 190,
              headerClass: 'header-complicated',
              headerName: 'НР',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell
                    originData={modifyNum(data?.curr?.overheads)}
                    isNum
                    float={'right'}>
                    {ceilData('curr', 'overheads', data)}
                    {/* {modifyData({ data: data?.curr?.overheads! })} */}
                  </Cell>
                );
              }
            },
            {
              field: 'spCurr',
              width: 105,
              hide: empty,
              // suppressMovable: true,
              // maxWidth: 105,
              minWidth: 105,
              headerClass: 'header-complicated',
              headerName: 'СП, %',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                const renderPercent =
                  data?.type !== 'file'
                    ? modifyData({ data: data?.curr?.sp!, replace: true })
                    : null;
                return (
                  <Cell
                    originData={modifyNum(data?.curr?.sp!)}
                    isNum
                    float={'right'}>
                    {renderPercent ? renderPercent + '%' : null}
                  </Cell>
                );
              }
            },
            {
              field: 'estimatedProfitCurr',
              width: 190,
              hide: empty,
              // suppressMovable: true,
              // maxWidth: 190,
              minWidth: 190,
              headerClass: 'header-complicated',
              headerName: 'СП',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell
                    originData={modifyNum(data?.curr?.estimatedProfit)}
                    isNum
                    float={'right'}>
                    {ceilData('curr', 'estimatedProfit', data)}
                    {/* {modifyData({ data: data?.curr?.estimatedProfit! })} */}
                  </Cell>
                );
              }
            },
            {
              field: 'totalWorkCostCurr',
              hide: !total?.curr?.dynamicRows?.length || empty,
              width: 190,
              // suppressMovable: true,
              // maxWidth: 190,
              minWidth: 190,
              headerClass:
                'header-top-border header-left-border header-right-border header-complicated',
              cellClass: 'header-left-border header-right-border',
              headerName: 'Итого',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell isNum float={'right'}>
                    {ceilData('curr', 'totalWorkCost', data)}
                    {/* {modifyData({ data: data?.curr?.totalWorkCost! })} */}
                  </Cell>
                );
              }
            },
            ...(!total?.curr?.dynamicRows?.length
              ? ([] as any)
              : total.curr.dynamicRows.map((dyn, idxDyn) => {
                  return {
                    field: dyn.title + 'Curr',
                    width: 190,
                    hide: empty,
                    // suppressMovable: true,
                    // maxWidth: 190,
                    minWidth: 190,
                    headerClass: 'header-complicated',
                    headerName: dyn.title,
                    cellRenderer: ({
                      data
                    }: ICellRendererParams<
                      IComplicatedIndexMethod,
                      any,
                      any
                    >) => {
                      return (
                        <Cell
                          originData={modifyNum(
                            data?.curr?.dynamicRows?.[idxDyn]?.value!
                          )}
                          isNum
                          float={'right'}>
                          {data?.curr?.dynamicRows?.[idxDyn]?.value
                            ? modifyData({
                                data: Math.round(
                                  data?.curr?.dynamicRows?.[idxDyn]?.value!
                                )
                              })
                            : null}
                          {/* {modifyData({
                            data: data?.curr?.dynamicRows?.[idxDyn]?.value!
                          })} */}
                        </Cell>
                      );
                    }
                  };
                })),
            {
              field: 'workCostInRublesCurr',
              width: 190,
              hide: empty,
              // suppressMovable: true,
              // maxWidth: 190,
              minWidth: 190,
              headerClass:
                'header-top-border header-left-border header-right-border header-complicated',
              cellClass: 'header-left-border header-right-border',

              headerName: 'Всего стоимость',
              cellRenderer: ({
                data
              }: ICellRendererParams<IComplicatedIndexMethod, any, any>) => {
                return (
                  <Cell
                    originData={modifyNum(data?.curr?.workCostInRubles)}
                    isNum
                    float={'right'}>
                    {ceilData('curr', 'workCostInRubles', data)}
                    {/* {modifyData({ data: data?.curr?.workCostInRubles! })} */}
                  </Cell>
                );
              }
            }
          ]
        }
      ];
    },
    []
  );

  // useLayoutEffect(() => {
  //   if (calcID) {
  //     if (firstLoad.current) {
  //       const storage =
  //         getLocalStorage(ENUMLocalStorage.levelsBaseMethodCalc) ?? [];
  //       const findLevels = storage.find((level) => level.id === calcID);
  //       if (findLevels) {
  //         dispatch(setHiddenRows(new Set(findLevels.levels)));
  //       }
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!firstLoad.current) {
  //     setValue((prevState) => {
  //       const isFind = prevState?.find((level) => level.id === calcID);
  //       if (isFind) {
  //         return prevState?.map((level) => {
  //           if (level.id === calcID) {
  //             return {
  //               id: Number(calcID),
  //               levels: Array.from(hiddenRowsIds)
  //             };
  //           }
  //           return level;
  //         });
  //       } else {
  //         return [
  //           ...(prevState ?? []),
  //           {
  //             id: Number(calcID),
  //             levels: Array.from(hiddenRowsIds)
  //           }
  //         ];
  //       }
  //     });
  //   }
  // }, [hiddenRowsIds]);

  useBreadcrumbs(
    [
      { title: <ProjectLabel /> },
      {
        title: 'Расчеты',
        url: `/projects/${projectID}/calculations`
      },
      {
        title: calculation?.title ? (
          <CalculationLabel
            title={calculation?.title}
            type={calculation?.type}
          />
        ) : (
          'Предпросмотр'
        ),
        url: `projects/${projectID}/calculation/${calculation?.id}/edit`
      }
    ],
    [calculation?.title]
  );

  const doesExternalFilterPass = useCallback(
    (params: any) => {
      try {
        Ref.current?.api.setIsExternalFilterPresent(() => false);

        if (params.data?.parentID) {
          return hiddenRowsIds.has(params.data?.parentID);
        }
        return true;
      } catch (e) {
        return false;
      }
    },
    [hiddenRowsIds]
  );

  useEffect(() => {
    if (
      (table?.length && list.length) ||
      (!isNotFetched && depth.calculation <= 1)
    ) {
      firstLoad.current = true;
      // dispatch(
      //   getData({ limit: LIMIT, page: 0, calcID, depth: depth.calculation })
      // ).then((resp) => {
      //   console.log(resp);
      dispatch(initList({ list: [], total }));
      setTable([]);
      // });
    }
  }, [depth]);

  // useEffect(() => {
  //   Ref.current?.api?.setIsExternalFilterPresent(() => true);
  //   Ref.current?.api?.onFilterChanged();
  // }, [doesExternalFilterPass]);

  // useEffect(() => {
  //   if (!firstLoad.current) {
  //     const idsNumbers: number[] = [];
  //     Ref.current?.api?.forEachNode((node) => {
  //       if (!((node.data?.level || 1) >= depth.calculation)) {
  //         idsNumbers.push(node.data!.id);
  //       }
  //     });
  //     console.log(idsNumbers);
  //     dispatch(setHiddenRows(new Set(idsNumbers)));
  //   } else {
  //     firstLoad.current = false;
  //   }
  // }, [depth.calculation]);

  const getDataFn = useCallback(
    (params: any) => {
      // firstLoad становится true при входе на страницу или изменении степпера, page меняется после завершения appendData на текущую страницу и при изменении степпера на 0
      // это решает проблему, что на последней строке таблицы должен уходить запрос на след страницу, но при этом при изменении степпера страница должна стать 0
      params.page = firstLoad.current ? page : params.page;
      const sendingFunc: any = params.page >= 1 ? appendData : getData;
      params.depth = depth.calculation;
      if (params.page === 0) firstLoad.current = false;
      return sendingFunc(params);
    },
    [depth.calculation, page]
  );
  usePagination({
    initialParams: {
      limit: LIMIT,
      page: 0,
      calcID,
      depth: depth.calculation
    },
    sendFn: getDataFn,
    // thenFn: () => {
    //   dispatch(togglePage());
    // },
    // catchFn: () => {
    //   dispatch(resetTable());
    // },
    resetToInitialDeps: [depth.calculation],
    requiredDeps: [
      calcID,
      !isPreview,
      depth.calculation > 0 && depth.calculation <= maxDepth.calculation,
      depth.calculation > 1 ? true : !list.length
    ],
    isAllDataFetched: endFetch
  });

  useEffect(() => {
    if (Ref.current) {
      dispatch(setGridRef(Ref.current));
    }
  }, [Ref.current]);

  // useEffect(() => {
  //   if (!table?.length && !list.length && depth.calculation === 1) {
  //     dispatch(
  //       getData({ limit: LIMIT, page: 0, calcID, depth: depth.calculation })
  //     );
  //   }
  // }, [list.length, table?.length, depth.calculation]);

  useEffect(() => {
    return () => {
      // setTable([]);
      // dispatch(resetTable());
    };
  }, []);

  const pinTotal = useMemo(() => {
    return isPreview ? [serverDataPreview?.total] : total;
  }, [isPreview, serverDataPreview?.total, total]);
  console.log('endFetch>>>', endFetch, [
    calcID,
    !isPreview,
    depth.calculation > 0 && depth.calculation <= maxDepth.calculation,
    !list.length
  ]);

  useEffect(() => {
    if (isPreview) return;
    console.log('loader', loader, table?.length);
    if (loader && !table?.length) {
      Ref.current?.api?.showLoadingOverlay();
    } else {
      // Ref.current?.api?.hideOverlay()
    }
  }, [loader, table, isPreview]);

  return (
    <>
      {loader && table && !!table.length && (
        <CircularProgress
          size={50}
          sx={{
            zIndex: 100,
            position: 'absolute',
            top: '50%',
            left: '50%',
            translate: '-50% -50%'
          }}
        />
      )}
      <>
        <PageStyled>
          <div />
          <WrapperAgGrid className="ag-theme-material reference-prices">
            <AgGridReact
              ref={Ref}
              defaultColDef={{
                resizable: true,
                lockPinned: true
              }}
              suppressCellFocus
              enableCellTextSelection
              ensureDomOrder
              maintainColumnOrder
              columnDefs={generateColumns({
                collapseFn: collapse,
                hiddenRowsIds,
                total: pinTotal[0]
              })}
              groupHeaderHeight={25}
              gridOptions={gridOption}
              context={{
                toggleDrawer: setFilterDrawer,
                hiddenRowsIds,
                setHiddenRows: (set: Set<number>) =>
                  dispatch(setHiddenRows(set))
              }}
              pinnedTopRowData={isPreview ? [] : table?.length ? pinTotal : []}
              rowData={
                isPreview ? (serverDataPreview?.data ?? []) : (table ?? [])
              }
              onFirstDataRendered={(event) => {
                event.api.sizeColumnsToFit();
                // event.api?.setIsExternalFilterPresent(() => true);
                // event.api?.onFilterChanged();
              }}
              getRowClass={getRowClass}
              getRowHeight={(params) => {
                if (params.node.rowPinned === 'top') {
                  return 50;
                }
                return 55;
              }}
              rowStyle={{
                padding: '0 !important'
              }}
              rowHeight={55}
              headerHeight={36}
              // doesExternalFilterPass={doesExternalFilterPass}
              loadingOverlayComponent={Progress}
              noRowsOverlayComponent={
                isNotFetched && !isPreview ? Progress : NoRows
              }></AgGridReact>
          </WrapperAgGrid>
          <IndexMethodCalculationDialogFilter
            open={filterDrawer}
            activeFilters={bodyParams}
            changeFilter={onChangeParams}
            onClose={() => setFilterDrawer(null)}
          />
          <ConfirmDialogDelete />
          <ConfirmDialogDeleteRowsWithExecution />
          {deleteResponse.isLoading && <DeleteProcessModal open={true} />}
        </PageStyled>
      </>
    </>
  );
};

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, IconButton, Stack, Tab, Tabs } from '@mui/material';
import { ReactComponent as SuccessIcon } from 'assets/icons/cloudSuccessWhite.svg';
import {
  FC,
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useContext,
  useState
} from 'react';
import {
  getBimCalcState,
  setHiddenRows,
  toggleCollapseMod,
  updateExecutionTableState
} from 'store/slices/calculations/bim/bim.slice';
import { useAppDispatch, useTypedSelector } from 'store/store';
import { ActList, CalcData } from 'types';
import Button from '../../../../../../components/Button';
import Stepper from '../../../../../../components/Stepper';
import { useStepperContext } from '../../../../../../hooks/useStepper';
import { ImportButton } from '../../../../../Administration/AdminReferences/Prices/components/PricesReferences.style';
import { ActListContext, BimStepper } from '../../../CalculationСomplicated';
import { ParametersDialog } from '../ParametersDialog';
import Tooltip from 'components/Tooltip';
import { calculationsApi } from 'api/calculations';
import { useProjectId } from 'hooks/useProjectId';
import { useSnackbar } from 'notistack';
import { DeleteProcessModal } from 'pages/Calculations/components/DeleteProcessModal';
import { useDeleteEstimateInCalculation } from 'hooks/useDeleteEstimateInCalculation';
import { useCalcId } from 'hooks/useCalcId';
import { ExecutionState } from 'pages/Calculations/components/CalculationСomplicated/CalculationСomplicated.types';

export type CalculationLegendProps = {
  calculationStatus: string;
  currentAct?: ActList | null;
  hideButton: boolean;
  calculation: CalcData;
  selectedTab?: number;
  openDrawer?: () => void;
  handleOpenMenuCalculation: (event: MouseEvent<HTMLButtonElement>) => void;
  handleSelectedTab?: (event: SyntheticEvent, newValue: number) => void;
};

const CalculationLegend: FC<CalculationLegendProps> = ({
  selectedTab,
  calculation,
  currentAct,
  hideButton,
  openDrawer,
  handleSelectedTab,
  handleOpenMenuCalculation
}) => {
  const { collapseMode } = useTypedSelector(getBimCalcState);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const projectID = useProjectId();
  const { enqueueSnackbar } = useSnackbar();

  const { toggleDepth, maxDepth, depth } = useStepperContext<BimStepper>();

  const openDialogHandler = () => {
    setOpen(true);
  };

  const closeDialogHandler = useCallback(() => {
    setOpen(false);
  }, []);

  const calcID = useCalcId();
  const { setSelectedRowsIds, selectedRowsIds, getTable, data } =
    useContext(ActListContext);
  const {
    ConfirmDialogDelete,
    openConfirmDelete,
    ConfirmDialogDeleteRowsWithExecution,
    deleteResponse
  } = useDeleteEstimateInCalculation({
    setDeletedIds: setSelectedRowsIds,
    deletedIds: selectedRowsIds || [],
    deletedDataType: 'rows',
    onDelete: () => {
      toggleDepth('calculation', 0);
      dispatch(
        calculationsApi.util.invalidateTags([
          'BimChildren',
          'CalculationEstimatesById'
        ])
      );
      dispatch(setHiddenRows(new Set()));

      getTable?.(Number(calcID), 0, 0, true);
    }
  });

  return (
    <>
      <Box height={'48px'} className="CalculationLegend">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          // pb={1.5}
        >
          <Stack direction="row" flexGrow={1}>
            <Stack
              spacing={5}
              flexGrow={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center">
              {hideButton ? (
                <span />
              ) : (
                <Tabs value={selectedTab} onChange={handleSelectedTab}>
                  <Tab label="Расчет" />
                  <Tab label="Укрупненные" />
                  <Tab label="Ресурсы" />
                  <Tab label="Выполнение" />
                </Tabs>
              )}
              {hideButton ? (
                <span />
              ) : (
                <Stack direction="row" spacing={1.5}>
                  {selectedTab === 0 && (
                    <IconButton
                      onClick={() => openConfirmDelete()}
                      disabled={!selectedRowsIds?.length}
                      color="error">
                      <Tooltip title="Удалить выбранные расценки">
                        <DeleteOutlineIcon fontSize={'small'} />
                      </Tooltip>
                    </IconButton>
                  )}
                  {(selectedTab === 0 || selectedTab === 3) && (
                    <Stepper
                      sx={{ alignSelf: 'center' }}
                      // name={
                      //   collapseMode === 'mixed' ? 'Произвольно' : undefined
                      // }
                      count={
                        selectedTab === 0
                          ? maxDepth.calculation
                          : maxDepth.executed
                      }
                      page={
                        selectedTab === 0 ? depth.calculation : depth.executed
                      }
                      onChange={(_, page) => {
                        dispatch(toggleCollapseMod(page < 1 ? 1 : page));

                        toggleDepth(
                          selectedTab === 0 ? 'calculation' : 'executed',
                          page
                        );
                      }}
                    />
                  )}
                  {/* кнопка вызова дровера настройки интеграции */}
                  {!!calculation?.integrationInfo && (
                    <Button
                      disabled={!currentAct}
                      onClick={openDrawer}
                      style={{
                        padding: '8px',
                        minWidth: '40px'
                      }}>
                      <SuccessIcon />
                    </Button>
                  )}
                  {(selectedTab === 0 || selectedTab === 3) &&(
                    <ImportButton
                      variant={'text'}
                      onClick={openDialogHandler}
                      disabled={
                        selectedTab === 3 ? !data?.actList.length : false
                      }>
                      <div>
                        <FormatListBulletedIcon />
                        Параметры {selectedTab === 3 ? 'актов' : 'расчета'}
                      </div>
                    </ImportButton>
                  )}

                  <Button
                    onClick={handleOpenMenuCalculation}
                    style={{
                      padding: '8px',
                      minWidth: '40px'
                    }}>
                    <MoreHorizIcon />
                  </Button>
                  {/*<StyledMenuButton variant="outlined" onClick={handleOpenMenuCalculation}>*/}
                  {/*  <MoreHorizIcon style={{ fill: '#FFF' }} />*/}
                  {/*</StyledMenuButton>*/}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <ParametersDialog
        open={open}
        onClose={closeDialogHandler}
        isExecution={selectedTab === 3}
      />
      <ConfirmDialogDelete />
      <ConfirmDialogDeleteRowsWithExecution />
      {deleteResponse.isLoading && <DeleteProcessModal open={true} />}
    </>
  );
};

export default CalculationLegend;

import { ColDef, ColGroupDef, IRowNode } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import Progress from 'components/Progress';
import { ENUMLocalStorage, useLocalStorage } from 'hooks/use-local-storage';
import { WrapperAgGrid } from 'pages/Calculations/components/Accomplishment/Accomplishment.styles';
import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  ComplicatedDynamicRow,
  ComplicatedRimRow,
  IIndexMethodTable
} from 'types/index-method/index-method';
import { v4 as uuid4 } from 'uuid';
import {
  CellRendererIndexMethod,
  customHeader,
  customHeaderMultiline,
  getHeadersDefaultIndexMethodCalculation,
  getRowClassIndexMethodCalculation,
  HeaderComponentWithFilter,
  headerRendererIndexMethod,
  IIndexMethodCalculationContext,
  IndexMethodCalculationTableProps,
  TChips
} from '.';
import {
  calculationsApi,
  useGetCalculationCalcIDComplicatedPreviewQuery
} from '../../../api/calculations';

import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Stack } from '@mui/material';
import Tooltip from 'components/Tooltip';
import {
  Chip,
  ChipWrapper,
  Devide
} from 'pages/Administration/AdminReferences/Prices/components/PricesReferences.style';
import {
  IComplicatedIndexMethod,
  RimRequest
} from '../../../api/calculations/types';
import { usePagination } from '../../../hooks/use-pagination';
import { useStepperContext } from '../../../hooks/useStepper';
import { setGridRef } from '../../../store/slices/ag-grid/ag-grid-slice';
import {
  appendData,
  getData
} from '../../../store/slices/calculations/rim/actions';
import {
  changeFilters,
  getRimCalcState,
  resetRimExecution,
  reset as resetTable,
  setHiddenRows,
  togglePage
} from '../../../store/slices/calculations/rim/rim.slice';
import { useAppDispatch, useTypedSelector } from '../../../store/store';
import { Stepper } from '../index-method';
import {
  IndexMethodCalculationDialogFilter,
  title
} from './index-method-calculation.dialog-filter';
import { getChildren } from '../../../store/slices/calculations/rim/actions';
import {
  filterByID,
  initList
} from '../../../store/slices/calculations/rim/rim.slice';
import { useDeleteEstimateInCalculation } from 'hooks/useDeleteEstimateInCalculation';
import { DeleteProcessModal } from 'pages/Calculations/components/DeleteProcessModal';
import { NoRows } from 'pages/Administration/AdminReferences/Prices/useTableData';

const defaultColDef = { resizable: true };
const LIMIT = 10000;
export const IndexMethodCalculation: FC<IndexMethodCalculationTableProps> = (
  props
) => {
  const dispatch = useAppDispatch();
  const {
    list,
    total,
    limitedRows,
    hiddenRows,
    endFetch,
    page,
    loader,
    reset,
    filters,
    isNotFetched
  } = useTypedSelector(getRimCalcState);

  const [table, setTable] = useState<undefined | ComplicatedRimRow[]>([]);

  const { setMaxDepth, depth, maxDepth, toggleDepth } =
    useStepperContext<Stepper>(function () {
      // if (!props.firstLoad) {
      //   const hideRows =
      //     data
      //       ?.filter((el) => {
      //         return el.lvl && el.lvl >= depth.indexPage;
      //       })
      //       ?.map((_) => _.id! as number) ?? [];
      //   setCollapseRowsIds(hideRows);
      //   ref.current?.api?.setIsExternalFilterPresent(() => true);
      //   ref.current?.api?.onFilterChanged();
      // }
    }, 'indexPage');

  const {
    data: previewData,
    isFetching: isFetchingCalculationComplicated,
    isLoading: previewLoading
  } = useGetCalculationCalcIDComplicatedPreviewQuery(
    {
      type: 'rim',
      isPreview: true,
      fileID: props.fileID,
      projectID: props.projectID
    },
    { skip: props.mode === 'editor' }
  );

  // code?: string[]   header?: string[]   lsr?: string[]   title?: string[]   unit?: string[]
  useLayoutEffect(() => {
    setMaxDepth('indexPage', 3);
  }, []);
  useEffect(() => {
    if (!table || !table.length) {
      // const newList = limitedRows.length
      //   ? list.concat(
      //       [
      //         {
      //           id: -limitedRows[0].id,
      //           type: 'limit-header' as ComplicatedRimRow['type'],
      //           title: 'лимитированные',
      //           hasChildren: false,
      //           including: {
      //             building: null,
      //             equipment: null,
      //             mounting: null,
      //             other: null
      //           },
      //           lvl: 1
      //         }
      //       ].concat(limitedRows! as any)
      //     )
      //   : list;
      setTable(props.mode === 'editor' ? list : previewData?.data);
    }
  }, [list, previewData?.data, props.mode, table]);

  const [filterDrawer, setFilterDrawer] = React.useState<keyof TChips | null>(
    null
  );

  // const [bodyParams, setBodyParams] = React.useState<Required<TChips>>({
  //   lsr: [],
  //   chapter: [],
  //   header: [],
  //   code: [],
  //   title: [],
  //   unit: []
  // });

  const onChangeParams = React.useCallback(
    (key: keyof TChips, target: string | string[]) => {
      // const newState = { ...bodyParams.current };
      dispatch(changeFilters({ key, target }));
      // setBodyParams((prevState) => {
      //   const newState: typeof prevState = {
      //     ...prevState
      //   };
      //   if (typeof target === 'string') {
      //     newState[key] = newState[key].includes(target)
      //       ? newState[key].filter((el) => el !== target)
      //       : newState[key].concat(target);
      //   } else if (Array.isArray(target)) {
      //     newState[key] = target;
      //   }
      //   // bodyParams.current = newState;
      //   return newState;
      // });
      dispatch(resetTable());
    },
    []
  );

  // useEffect(() => {
  //   let savedStateFilters:string|null|Required<TChips> = localStorage.getItem('bodyParams')
  //   if(savedStateFilters){
  //     savedStateFilters = JSON.parse(savedStateFilters) as Required<TChips>;
  //     setBodyParams(savedStateFilters)
  //   }
  // }, []);

  const ref = useRef<AgGridReact<IIndexMethodTable> | null>(null);
  // const levelID = useMemo(
  //   () => Number(props.calcID || props.fileID || 0),
  //   [props.calcID, props.fileID]
  // );
  // const { setValue: setLevelsNotPreview, firstValue: firstLevelsNotPreview } =
  //   useLocalStorage(ENUMLocalStorage.levelsIndexMethod, []);
  // const { setValue: setLevelsPreview, firstValue: firstLevelsPreview } =
  //   useLocalStorage(ENUMLocalStorage.levelsIndexMethodPreview, []);

  // const setLevels = useMemo(
  //   () => (props.mode === 'editor' ? setLevelsNotPreview : setLevelsPreview),
  //   [props.mode, setLevelsNotPreview, setLevelsPreview]
  // );
  // const firstLevels = useMemo(
  //   () =>
  //     props.mode === 'editor' ? firstLevelsNotPreview : firstLevelsPreview,
  //   [firstLevelsNotPreview, firstLevelsPreview, props.mode]
  // );

  // const [getDataOld, { data: calcData, isLoading: calcLoading }] =
  //   useGetCalculationCalcIDComplicatedMutation();

  // const dataCalculationComplicated = useMemo(() => {
  //   if (props.mode === 'editor') {
  //     return list;
  //   }
  //   return previewData;
  // }, [previewData, props.mode]);
  const isLoadingCalculationComplicated = useMemo(() => {
    if (props.mode === 'editor') {
      return loader;
    }
    return previewLoading;
  }, [previewLoading, props.mode]);

  // useLayoutEffect(() => {
  //   console.log(props.updateFragment);
  //   if (props.mode === 'editor') {
  //     console.log(props.updateFragment);
  //     getDataOld({
  //       calcID: props.calcID,
  //       type: 'rim',
  //       page: 0,
  //       limit: 200,
  //       isPreview: false,
  //       body: bodyParams
  //     });
  //   }
  // }, [props.calcID, props.mode, bodyParams, props.updateFragment]);

  const isLoading = useMemo(
    () => isFetchingCalculationComplicated || isLoadingCalculationComplicated,
    [isFetchingCalculationComplicated, isLoadingCalculationComplicated]
  );

  const renderLimitedRows: ComplicatedRimRow[] = useMemo(() => {
    //limit-header
    const limited =
      props.mode === 'editor'
        ? limitedRows
        : (previewData?.limitedCostsAsRows ?? []);
    const d: ComplicatedRimRow[] = [
      {
        id: -limited[0]?.id,
        type: 'limit-header',
        title: 'лимитированные',
        hasChildren: false,
        including: {
          building: null,
          equipment: null,
          mounting: null,
          other: null
        },
        lvl: 1
      }
    ];
    if (limited.length) {
      return d.concat(
        limited.map((el) => ({
          ...el,
          id: uuid4(),
          type: 'limit'
        }))
      );
    }
    return [];
  }, [limitedRows, previewData]);

  // const data: IIndexMethodTable[] = useMemo(() => {
  //   let lvl = 1;
  //   const mappedData =
  //     dataCalculationComplicated?.data.map((e) => {
  //       if (e.lvl > lvl) {
  //         lvl = e.lvl;
  //         if (e.hasChildren) {
  //           lvl = e.lvl + 1;
  //         }
  //       }
  //       return {
  //         ...e
  //       };
  //     }) ?? [];
  //   setMaxDepth('indexPage', lvl);
  //
  //   return mappedData;
  // }, [dataCalculationComplicated?.data]);

  // const defaultData: IIndexMethodTable[] = useMemo(() => {
  //   return (
  //     dataCalculationComplicated?.data.map((e) => ({
  //       ...e
  //     })) ?? []
  //   );
  // }, [dataCalculationComplicated?.data]);

  // const total = useMemo(() => {
  //   return dataCalculationComplicated?.total
  //     ? [dataCalculationComplicated?.total]
  //     : [];
  // }, [dataCalculationComplicated?.total]);

  // const totalDynamicRows: ComplicatedDynamicRow[] = useMemo(() => {
  //   return total ? (total[0]?.dynamicRows ?? []) : [];
  // }, [total]);

  const [columnDefs, setColumnDefs] = useState<
    (ColDef<IIndexMethodTable> | ColGroupDef<IIndexMethodTable>)[] | undefined
  >();

  const pinnedTopRowData = useMemo(
    () => (columnDefs?.length ? total : undefined),
    [columnDefs?.length, total]
  );

  // const [collapseRowsIds, setCollapseRowsIds] = useState<number[]>([]);
  //
  // const collapseRows = React.useMemo(() => {
  //   if (Object.values(bodyParams).flat().length) {
  //     return [] as typeof collapseRowsIds;
  //   }
  //   return collapseRowsIds;
  // }, [collapseRowsIds, bodyParams]);

  const onCollapse = useCallback(
    (id: number, rowIndex: number, actualSet: Set<number>) => {
      const newSet = new Set(actualSet);
      if (!actualSet.has(id as number)) {
        newSet.add(id as number);
        dispatch(setHiddenRows(newSet));
        dispatch(
          getChildren({
            calcID: props.calcID,
            rowID: id,
            index: rowIndex,
            action: !actualSet.has(id as number) ? 'add' : 'remove'
          })
        );
      } else {
        newSet.delete(id as number);
        const remove: ComplicatedRimRow[] = [];
        const parentsSet = new Set<number>();
        parentsSet.add(id);
        ref.current?.api.forEachNode((n) => {
          if (n.data?.parentID! === id || parentsSet.has(n.data?.parentID!)) {
            remove.push(n.data!);
            parentsSet.add(Number(n.data?.id!));
            newSet.delete(n.data?.id! as number);
          }
        });
        dispatch(setHiddenRows(newSet));
        ref.current?.api.applyTransaction({ remove });
        dispatch(filterByID(parentsSet));
      }
      // const updateNode = ref.current?.api.getRowNode(String(id));
      // if (updateNode)
      // ref.current?.api.refreshCells({ force: true });
    },
    [dispatch, hiddenRows, props.calcID]
  );

  // const doesExternalFilterPass = useCallback(
  //   (params: IRowNode<IIndexMethodTable>) => {
  //     const isEmptyBodyParams = !!Object.values(bodyParams).flat().length;
  //
  //     if (isEmptyBodyParams) return true;
  //     try {
  //       ref.current?.api.setIsExternalFilterPresent(() => false);
  //
  //       if (params.data?.parentID) {
  //         return hiddenRows.has(params.data?.parentID);
  //       }
  //       return true;
  //     } catch (e) {
  //       return false;
  //     }
  //   },
  //   [hiddenRows, bodyParams]
  // );
  // const doesExternalFilterPass = useCallback(
  //   (params: IRowNode<IIndexMethodTable>) => {
  //     ref.current?.api.setIsExternalFilterPresent(() => false);
  //     const shouldClose = params.data?.parentID
  //       ? hiddenRows.has(params.data?.parentID!)
  //       : true;
  //     return shouldClose;
  //   },
  //   [hiddenRows]
  // );

  const contextTable = useMemo<IIndexMethodCalculationContext>(
    () => ({
      collapseRowsIds: hiddenRows,
      onCollapse,
      toggleDrawer: setFilterDrawer,
      bodyParams: filters
    }),
    [onCollapse, setFilterDrawer, filters, hiddenRows]
  );

  const [deletedRowId, setDeletedRowId] = useState<number | null>(null);
  const {
    ConfirmDialogDelete,
    openConfirmDelete,
    ConfirmDialogDeleteRowsWithExecution,
    deleteResponse
  } = useDeleteEstimateInCalculation({
    deletedIds: deletedRowId ? [deletedRowId] : [],
    deletedDataType: 'estimate',
    onDelete: () => {
      toggleDepth('indexPage', 0);
      dispatch(
        calculationsApi.util.invalidateTags([
          'BimChildren',
          'CalculationEstimatesById'
        ])
      );
      dispatch(resetRimExecution());
    }
  });

  const handleEstimateDeleteClick = (id: number) => {
    setDeletedRowId(id);
    openConfirmDelete();
  };

  useEffect(() => {
    // if (list.length && !isFetchingCalculationComplicated) {
    // console.log('update');
    // setColumnDefs([]);
    // setTimeout(() => {
    if (total[0]?.dynamicRows || previewData?.total?.dynamicRows) {
      setColumnDefs([
        ...getHeadersDefaultIndexMethodCalculation({
          totalDynamicRows:
            props.mode === 'editor'
              ? (total[0]?.dynamicRows ?? [])
              : (previewData?.total?.dynamicRows ?? []),
          onDelete: handleEstimateDeleteClick
        })
      ]);
    }
    // }, 200);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    total,
    previewData,
    reset
    // list.length,
    // totalDynamicRows.length,
    // isFetchingCalculationComplicated,
    // props.updateFragment
  ]);
  useEffect(() => {
    return () => {
      ref.current?.columnApi?.resetColumnState();
      setColumnDefs([]);
      setTable([]);
    };
  }, [reset]);
  useEffect(() => {
    const newColumnState = ref.current?.columnApi
      ?.getColumnState()
      .sort((a, b) => ((a.sortIndex || 0) < (b.sortIndex || 0) ? -1 : 1));
    ref.current?.columnApi?.applyColumnState({
      state: newColumnState,
      applyOrder: true
    });
  }, [columnDefs, reset]);

  useEffect(() => {
    if (isFetchingCalculationComplicated) {
      setColumnDefs(undefined);
      props.toggleFirstLoad(true);
    }
  }, [isFetchingCalculationComplicated]);

  useEffect(() => {
    if (ref.current && ref.current.api) {
      if (isLoading) {
        ref.current.api.showLoadingOverlay();
      } else {
        ref.current.api.hideOverlay();
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (ref.current && ref.current.api) {
      // ref.current.api.refreshCells({ force: true, suppressFlash: true });
      // ref.current.api.refreshHeader();
    }
  }, [list]);

  // useEffect(() => {
  //   if (ref.current && ref.current.api) {
  //     ref.current.api.setIsExternalFilterPresent(() => true);
  //     ref.current.api.onFilterChanged();
  //     ref.current.api.refreshCells({ force: true, suppressFlash: true });
  //   }
  // }, [doesExternalFilterPass]);

  // useEffect(() => {
  //   if (!props.firstLoad) {
  //     // console.log(collapseRowsIds);
  //     setLevels((prevState: any) => {
  //       const isFind = prevState?.find((level: any) => level.id === levelID);
  //       if (isFind) {
  //         return prevState?.map((level: any) => {
  //           if (level.id === levelID) {
  //             return {
  //               id: Number(levelID),
  //               levels: Array.from(hiddenRows)
  //             };
  //           }
  //           return level;
  //         });
  //       } else {
  //         return [
  //           ...(prevState ?? []),
  //           {
  //             id: Number(levelID),
  //             levels: Array.from(hiddenRows)
  //           }
  //         ];
  //       }
  //     });
  //   }
  // }, [hiddenRows]);

  // useEffect(() => {
  //   if (!props.firstLoad) {
  //     const levels = getLocalStorage(ENUMLocalStorage.levelsIndexMethod);
  //     const isFind = (levels ?? []).find((level) => level.id === levelID);
  //     console.log(isFind);
  //     if (isFind) {
  //       dispatch(setHiddenRows(new Set(isFind.levels)));
  //       // setCollapseRowsIds(isFind.levels);
  //       props.toggleFirstLoad(false);
  //     }
  //     // else {
  //     //   if (list && list.length) {
  //     //     const firstElements = list
  //     //       .map((item) => (item.parentID === null ? item.id : null))
  //     //       .filter((item): item is number => item !== null);
  //     //     if (firstElements && firstElements.length) {
  //     //       onCollapse(firstElements);
  //     //     }
  //     //     props.toggleFirstLoad(false);
  //     //   }
  //     // }
  //   }
  // }, [levelID, props.calcID, props.fileID]);

  // useEffect(() => {
  // }, [depth.indexPage]);

  const chips = React.useMemo(() => {
    const entries = Object.entries(filters);
    if (!Object.values(filters).flat().length) return <span />;
    return (
      <Stack height={'min-content'} overflow={'hidden'} pt={'10px'}>
        <ChipWrapper>
          {entries.map(([key, value]) => {
            return !value.length ? null : (
              <Chip onClick={() => setFilterDrawer(key as keyof TChips)}>
                {title[key as keyof TChips]}
                <Devide />
                <Tooltip title={value.join(',')}>
                  <span>{value}</span>
                </Tooltip>
                <CloseIcon
                  fontSize={'small'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onChangeParams(key as keyof TChips, []);
                  }}
                />
              </Chip>
            );
          })}
        </ChipWrapper>
      </Stack>
    );
  }, [filters]);

  // useEffect(() => {
  //   if (!props.firstLoad) {
  //     const idsNumbers: number[] = [];
  //     ref.current?.api.forEachNode((node) => {
  //       if (!((node.data?.lvl || 1) >= depth.indexPage)) {
  //         idsNumbers.push(node.data!.id as any);
  //       }
  //     });
  //     dispatch(setHiddenRows(new Set(idsNumbers)));
  //   } else {
  //     props.toggleFirstLoad(false);
  //   }
  // }, [depth.indexPage]);

  const getDataFn = useCallback(
    (params: RimRequest) => {
      // if (params.page === 0) props.toggleFirstLoad(false);
      const sendFunction = page === 0 ? getData : appendData;
      params.page = page;
      params.body = filters;
      params.depth = depth.indexPage;
      return sendFunction(params);
    },
    [page, filters, depth.indexPage]
  );
  usePagination<RimRequest>({
    sendFn: getDataFn,
    initialParams: {
      calcID: props.calcID,
      page: 0,
      limit: LIMIT,
      depth: depth.indexPage,
      type: 'rim',
      body: filters,
      isPreview: false
    },
    isAllDataFetched: endFetch,
    requiredDeps: [
      !endFetch,
      props.calcID,
      ref.current,
      depth.indexPage > 0 && depth.indexPage <= maxDepth.indexPage,
      depth.indexPage > 1 ? true : !list.length
    ],
    resetToInitialDeps: [filters, reset, depth.indexPage]
  });

  useEffect(() => {
    if (ref.current) {
      dispatch(setGridRef(ref.current));
    }
  }, [ref.current]);

  useEffect(() => {
    if (
      (table?.length && list.length) ||
      (!isNotFetched && depth.indexPage <= 1)
    ) {
      // dispatch(
      //   getData({ limit: LIMIT, page: 0, calcID, depth: depth.calculation })
      // ).then((resp) => {
      //   console.log(resp);
      dispatch(initList({ list: [], total }));
      setTable([]);
      // });
    }
  }, [depth]);

  // TODO: удалить после определения как показывать загрузку
  // useEffect(() => {
  //   if (table && !!table.length) {
  //     ref.current?.api?.[loader ? 'showLoadingOverlay' : 'hideOverlay']?.();
  //   }
  // }, [loader, table]);

  console.log('bodyParams>>>', filters);

  return (
    <>
      {loader && table && !!table.length && (
        <CircularProgress
          size={50}
          sx={{
            zIndex: 100,
            position: 'absolute',
            top: '50%',
            left: '50%',
            translate: '-50% -50%'
          }}
        />
      )}
      <div
        style={{
          height: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: 'min-content 1fr',
          gap: '10px'
        }}>
        {chips}
        <WrapperAgGrid
          style={{ height: '100%' }}
          className="ag-theme-material reference-prices index-methods-calculation">
          <AgGridReact<IIndexMethodTable>
            ref={ref}
            context={{
              collapseRowsIds: hiddenRows,
              onCollapse,
              toggleDrawer: setFilterDrawer,
              bodyParams: filters
            }}
            defaultColDef={defaultColDef}
            singleClickEdit
            columnDefs={columnDefs}
            getRowClass={getRowClassIndexMethodCalculation}
            gridOptions={{
              components: {
                cellRenderer: CellRendererIndexMethod,
                headerComponent: headerRendererIndexMethod,
                customHeader,
                customHeaderMultiline,
                headerWithFilters: HeaderComponentWithFilter
              }
            }}
            pinnedTopRowData={pinnedTopRowData}
            rowData={
              table

              // list.concat(renderLimitedRows)
              // calcData?.data ?? []
              // rowData={
              //   isFetchingCalculationComplicated
              //     ? undefined
              //     : [...(rowData || []), ...limitedRows]
            }
            getRowId={(params) => (params.data.id || '').toString()}
            getRowHeight={(params) => {
              if (params.data?.type === 'limit-header') {
                return 30;
              }
              if (params.node.rowPinned === 'top') {
                return 50;
              }
              return 55;
            }}
            rowStyle={{
              padding: '0 !important'
            }}
            getRowStyle={(params) => {
              if (params.data?.type === 'limit-header') {
                return {
                  'pointer-events': 'none'
                };
              }
              return;
            }}
            suppressCellFocus
            enableCellTextSelection
            ensureDomOrder
            maintainColumnOrder
            rowHeight={55}
            groupHeaderHeight={40}
            headerHeight={46}
            // doesExternalFilterPass={doesExternalFilterPass}
            loadingOverlayComponent={Progress}
            noRowsOverlayComponent={isNotFetched ? Progress : NoRows}
            onFirstDataRendered={(event) => {
              event.api.sizeColumnsToFit();
              // event.api.setIsExternalFilterPresent(() => true);
              // event.api.onFilterChanged();
            }}
            // onGridSizeChanged={(event) => {
            //   event.api.sizeColumnsToFit();
            // }}
            // onViewportChanged={(event) => {
            //   event.api.sizeColumnsToFit();
            // }}
          />
        </WrapperAgGrid>
        <IndexMethodCalculationDialogFilter
          open={filterDrawer}
          activeFilters={filters}
          changeFilter={onChangeParams}
          onClose={() => setFilterDrawer(null)}
        />
        <ConfirmDialogDelete />
        <ConfirmDialogDeleteRowsWithExecution />
        {deleteResponse.isLoading && <DeleteProcessModal open={true} />}
      </div>
    </>
  );
};

import { Box, debounce, Divider, MenuItem, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ColumnResizedEvent, IRowNode } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { enqueueSnackbar } from 'notistack';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { v4 as uuid4 } from 'uuid';
import {
  useEditQuantityMutation,
  useGetExecutionCalculationRimChildrenMutation,
  useGetExecutionRimMutation,
  useLazyGetExecutionCalculationRimQuery
} from '../../../api/calculations';
import {
  useLazyGetKS2V2Query,
  useLazyGetKS6V2Query
} from '../../../api/params';
import { KSResponse } from '../../../api/params/params.types';
import Progress from '../../../components/Progress';
import { useStepperContext } from '../../../hooks/useStepper';
import {
  ActList,
  ActResponse,
  CalcData,
  ExecutionCalculationRimData
} from '../../../types';
import {
  PageStyled,
  WrapperAgGrid
} from '../../Calculations/components/Accomplishment/Accomplishment.styles';
import ActDialog from '../../Calculations/components/Accomplishment/components/ActDialog';
import { Badge } from '../../Calculations/components/Accomplishment/components/CaptionTable/CaptionTable';
import {
  SetupItem,
  SetupRow,
  SetupTable,
  StyledSelect
} from '../../Calculations/components/Accomplishment/components/CaptionTable/CaptionTable.style';
import CustomTableHeader from '../../Calculations/components/Accomplishment/components/CustomTableHeader';
import { StyledParametersButton } from '../../Calculations/components/CalculationBasic/components/CalculationLegend/CalculationLegend.styles';
import {
  ExecutedTabCellEditable,
  formatDateExecutedTab
} from '../../Calculations/components/CalculationDirectory/tabs/ExecutedTab';
import { Stepper } from '../index-method';
import {
  CellRendererIndexMethod,
  customHeader,
  customHeaderMultiline,
  getRowClassIndexMethodCalculation,
  IIndexMethodCalculationContext
} from '../index-method-calculation';
import {
  defaultDef,
  statusesAct,
  LIMIT
} from './index-method-execution.constants';
import { useTable } from './index-method-execution.functions';
import {
  UpdateParams,
  RimExecutionTableState
} from './index-method-execution.types';
import {
  agGridColumnResized,
  detectLastRowInView
} from '../../../utils/agGrid';
import { useAppDispatch, useTypedSelector } from '../../../store/store';
import {
  getAgGridRef,
  setGridRef
} from '../../../store/slices/ag-grid/ag-grid-slice';
import {
  getRimCalcState,
  setExecutionCollapseRowsIds,
  setExecutionRimTableState,
  updateExecutionExpandedHeadersIds,
  updateExecutionColumnsWidth,
  resetRimExecution
} from '../../../store/slices/calculations/rim/rim.slice';
import { NoRows } from 'pages/Administration/AdminReferences/Prices/useTableData';

const gridOptions = {
  components: {
    cellRenderer: CellRendererIndexMethod,
    headerComponent: CustomTableHeader,
    customHeader,
    customHeaderMultiline,
    ExecutedTabCellEditable
  }
};

export const IndexMethodExecutionTable: FC<{
  actList?: ActResponse;
  updateFragment: number;
  currentAct: ActList | null;
  changeCurrentAct: (act: ActList | null) => void;
  refetchActs: () => void;
  calculation?: CalcData;
  calcID: number;
  firstLoad: boolean;
  toggleFirstLoad: (f?: boolean) => void;
  projectID: number;
}> = (props) => {
  const [update] = useEditQuantityMutation();
  const gridRef = useTypedSelector(getAgGridRef);
  const ref = useRef<AgGridReact>(null);
  const dispatch = useAppDispatch();
  const [isRowUpdating, setIsRowUpdating] = useState(false);

  const [getKS2V2, { isFetching: isFetchingKS2V2 }] = useLazyGetKS2V2Query();
  const [getKS6V2, { isFetching: isFetchingKS6V2 }] = useLazyGetKS6V2Query();
  const [getChildren, { isLoading: isChildrenFetching }] =
    useGetExecutionCalculationRimChildrenMutation();
  const [getRimExecution] = useGetExecutionRimMutation();

  const [
    getExecutionCalculationRim,
    {
      data: dataCalculationComplicated,
      isFetching: isFetchingCalculationComplicated,
      isLoading: isLoadingCalculationComplicated,
      originalArgs
    }
  ] = useLazyGetExecutionCalculationRimQuery();

  //const [tableState, setTableState] = useState(new RimExecutionTableState());

  const {
    executionCollapseRowsIds,
    executionTableState,
    executionExpandedHeadersIds,
    executionColumnsWidth
  } = useTypedSelector(getRimCalcState);

  const onUpdate: UpdateParams = async (params, act) => {
    gridRef?.api.showLoadingOverlay();
    setIsRowUpdating(true);

    try {
      // First, update the UI optimistically with the current state
      dispatch((dispatch, getState) => {
        const currentState = getRimCalcState(getState());
        const currentTableState = currentState.executionTableState;
        const currentData = [...currentTableState.data.data];

        const itemIndex = currentData.findIndex(
          (item) => item.id === params.data.id
        );

        if (itemIndex !== -1) {
          const updatedParts = currentData[itemIndex].parts.map((value) =>
            value.actID === props.currentAct?.id
              ? {
                  ...value,
                  quantity: {
                    ...value.quantity,
                    total: params.newValue as number
                  }
                }
              : value
          );

          currentData[itemIndex] = {
            ...currentData[itemIndex],
            parts: updatedParts
          };

          dispatch(
            setExecutionRimTableState({
              ...currentTableState,
              data: { ...currentTableState.data, data: currentData }
            })
          );
        }
      });

      // Then, send the update to the server
      if (act && props.calcID) {
        const editedData = {
          actID: act,
          calcID: Number(props.calcID),
          body: {
            rowID: params.data.id,
            quantity:
              params.newValue === undefined ||
              params.newValue === null ||
              params.newValue === '0'
                ? null
                : Number(params.newValue)
          },
          shouldInvalidate: false
        };

        await update(editedData).then(() => props.refetchActs());

        const parentId = params.data.parentID;
        const rowId = params.data.id;
        if (!parentId) {
          gridRef?.api.hideOverlay();
          setIsRowUpdating(false);
          return;
        }

        // Get updated row and table data using thunk to access the latest state
        await dispatch(async (dispatch, getState) => {
          const currentState = getRimCalcState(getState());
          const currentData = currentState.executionTableState.data.data;

          const firstLevelTreeIndex =
            currentData
              .filter((item) => !item.parentID)
              ?.findIndex((item) => item.id === parentId) ?? -1;
          const page =
            firstLevelTreeIndex !== -1
              ? Math.floor(firstLevelTreeIndex / LIMIT)
              : 0;

          // Get updated row and table data
          const [updatedChildren, updatedTableData] = await Promise.all([
            getChildren({
              calcID: props.calcID,
              rowID: parentId
            }),
            getRimExecution({
              calcID: props.calcID,
              limit: LIMIT,
              page,
              depth: 1
            })
          ]);

          if (!('data' in updatedChildren)) {
            return;
          }

          // Update the state with the latest data from the server
          const latestState = getRimCalcState(getState());
          const latestTableState = latestState.executionTableState;
          const latestData = [...latestTableState.data.data];

          const updatedRow = updatedChildren.data.children.find(
            (item) => item.id === rowId
          );

          let updatedParentRow = null;
          if ('data' in updatedTableData) {
            updatedParentRow = updatedTableData.data.data.find(
              (item) => item.id === parentId
            );
          }

          const itemIndex = latestData.findIndex((item) => item.id === rowId);

          const parentIndex = latestData.findIndex(
            (item) => item.id === parentId
          );

          if (itemIndex !== -1) {
            if (updatedRow)
              latestData[itemIndex] = { ...updatedRow, parentID: parentId };
            if (updatedParentRow && parentIndex !== -1)
              latestData[parentIndex] = updatedParentRow;

            dispatch(
              setExecutionRimTableState({
                ...latestTableState,
                data: {
                  ...latestTableState.data,
                  data: latestData,
                  ...('data' in updatedTableData && {
                    total: updatedTableData.data.total
                  })
                },
                isFirstDataFetched: true
              })
            );
          }
        });
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      gridRef?.api.hideOverlay();
      setIsRowUpdating(false);
    }
  };

  const { setMaxDepth, depth } = useStepperContext<Stepper>();

  const checkedDepth = useMemo(() => {
    if (depth.executedPage < 1) return 1;

    return depth.executedPage > 3 ? 3 : depth.executedPage;
  }, [depth]);

  const dataFirstFilterTreeLength = useMemo(
    () => props.actList?.actList?.length,
    [props.actList?.actList]
  );
  const [createModal, setCreateModal] = useState(false);

  const renderAct = useMemo(() => {
    if (!props.currentAct) return 'Выберите диапазон';
    return formatDateExecutedTab(props.currentAct);
  }, [props.currentAct]);

  const isDepthChanged =
    (originalArgs?.depth ?? executionTableState.originalArgs?.depth) !==
    executionTableState.depth;

  const needUpdateDepth = useRef(false);
  const updateCounter = useRef(0);

  useEffect(() => {
    if (depth.executedPage === executionTableState.depth) {
      dispatch(resetRimExecution());
      needUpdateDepth.current = true;
      updateCounter.current++;
    }
  }, [depth]);

  useEffect(() => {
    if (dataCalculationComplicated) {
      dispatch(
        setExecutionRimTableState({
          ...new RimExecutionTableState(
            isDepthChanged ? undefined : executionTableState,
            dataCalculationComplicated
          ),
          depth: checkedDepth,
          originalArgs
        })
      );
      if (isDepthChanged) dispatch(setExecutionCollapseRowsIds(new Set()));
    }
  }, [dataCalculationComplicated]);

  useEffect(() => {
    if (dataCalculationComplicated) {
      dispatch(
        setExecutionRimTableState({
          ...new RimExecutionTableState(undefined, dataCalculationComplicated),
          depth: checkedDepth,
          originalArgs
        })
      );
      if (checkedDepth === 1) {
        dispatch(setExecutionCollapseRowsIds(new Set()));
      } else if (dataCalculationComplicated.data.length) {
        const newCollapseRowsIds: Set<number> = new Set(
          executionCollapseRowsIds
        );
        dataCalculationComplicated.data.forEach((item) => {
          if (checkedDepth > item.lvl && typeof item.id === 'number') {
            newCollapseRowsIds.add(item.id);
          } else if (checkedDepth <= item.lvl && typeof item.id === 'number') {
            newCollapseRowsIds.delete(item.id);
          }
        });
        dispatch(setExecutionCollapseRowsIds(newCollapseRowsIds));
      }
    }
  }, [updateCounter.current]);

  const isLoading = useMemo(
    () => isFetchingCalculationComplicated || isLoadingCalculationComplicated,
    [isFetchingCalculationComplicated, isLoadingCalculationComplicated]
  );
  const limitedRows: any[] = useMemo(() => {
    // limit-header
    if (executionTableState.data?.dynamicRows.length) {
      const d: any[] = [
        {
          id: uuid4(),
          type: 'limit-header',
          title: 'лимитированные',
          hasChildren: false,
          including: {
            building: null,
            equipment: null,
            mounting: null,
            other: null
          },
          lvl: 1
        }
      ];
      return d.concat(
        executionTableState.data.dynamicRows.map((el) => ({
          ...el,
          id: uuid4(),
          type: 'limit'
        }))
      );
    }
    return [];
  }, [executionTableState.data?.dynamicRows]);

  // const defaultData: ExecutionCalculationRimData[] = useMemo(() => {
  //   return (
  //     dataCalculationComplicated?.data.map((e) => ({
  //       ...e
  //     })) ?? []
  //   );
  // }, [dataCalculationComplicated?.data]);

  const total = useMemo(() => {
    return executionTableState.data?.total
      ? executionTableState.data?.total
      : [];
  }, [executionTableState.data?.total]);

  // const totalDynamicRows: any[] = useMemo(() => {
  //   return [];
  //   // return total ? (total[0]?.dynamicRows ?? []) : [];
  // }, [total]);

  const [columnDefs, resetColumns] = useTable(
    total[0],
    props.updateFragment,
    props.actList?.actList.length,
    executionCollapseRowsIds,
    ref.current,
    executionColumnsWidth
  );

  // const pinnedTopRowData = useMemo(
  //   () => (columnDefs && columnDefs?.length ? total : undefined),
  //   [columnDefs?.length, total]
  // );
  // const rowData = useMemo(
  //   () => (columnDefs && columnDefs?.length ? data : undefined),
  //   [columnDefs?.length, data]
  // );

  const onCollapse = useCallback(
    async (id: number) => {
      const newSet = new Set(executionCollapseRowsIds);
      const isHidden = !executionCollapseRowsIds.has(id);

      if (isHidden) {
        newSet.add(id);
        const result = await getChildren({ calcID: props.calcID, rowID: id });
        if (!('data' in result)) return;
        const newData = executionTableState.data.data.reduce(
          (accumulator, current) => {
            if (current.id === id && result.data.children?.length)
              return [...accumulator, current, ...result.data.children];

            return [...accumulator, current];
          },
          [] as ExecutionCalculationRimData[]
        );
        dispatch(
          setExecutionRimTableState({
            ...executionTableState,
            data: { ...executionTableState.data, data: newData }
          })
        );
      } else {
        newSet.delete(id);
        const deletedIds = new Set();
        const newData = executionTableState.data.data.filter((item) => {
          const isAllow =
            item.parentID !== id && !deletedIds.has(item.parentID);
          if (!isAllow && typeof item.id === 'number') {
            newSet.delete(item.id);
            deletedIds.add(item.id);
          }
          return isAllow;
        });
        dispatch(
          setExecutionRimTableState({
            ...executionTableState,
            data: { ...executionTableState.data, data: newData }
          })
        );
      }
      dispatch(setExecutionCollapseRowsIds(newSet));
    },
    [
      executionTableState.data?.data,
      gridRef,
      executionTableState,
      props.calcID,
      executionCollapseRowsIds
    ]
  );

  const updateVisibleRows = useCallback(() => {
    if (gridRef?.api && !executionTableState?.endFetched) {
      const isLastRowFullyVisible = detectLastRowInView(gridRef);

      if (
        isLastRowFullyVisible &&
        !!dataCalculationComplicated?.data?.length &&
        executionTableState.depth === checkedDepth
      ) {
        dispatch(
          setExecutionRimTableState({
            ...executionTableState,
            page: executionTableState.page + 1,
            depth: checkedDepth,
            isUpdating: true
          })
        );
      }
    }
  }, [gridRef, executionTableState, checkedDepth, dataCalculationComplicated]);

  const debouncedUpdateVisibleRows = useMemo(
    () => debounce(updateVisibleRows, 200),
    [updateVisibleRows, executionTableState]
  );

  const doesExternalFilterPass = useCallback(
    (params: IRowNode<ExecutionCalculationRimData>) => {
      gridRef?.api.setIsExternalFilterPresent(() => false);
      return params.data?.parentID
        ? executionCollapseRowsIds.has(params.data?.parentID)
        : true;
    },
    [executionCollapseRowsIds]
  );

  const handleExecutionExpandedHeadersIds = useCallback(
    (groupId: string) => {
      const newSet = new Set(executionExpandedHeadersIds);
      newSet.has(groupId) ? newSet.delete(groupId) : newSet.add(groupId);
      dispatch(updateExecutionExpandedHeadersIds(newSet));
    },
    [executionExpandedHeadersIds]
  );

  const contextTable = useMemo<IIndexMethodCalculationContext>(
    () => ({
      collapseRowsIds: executionCollapseRowsIds,
      onCollapse: onCollapse as any,
      current: props.currentAct,
      onUpdate,
      executionExpandedHeadersIds,
      handleExecutionExpandedHeadersIds
    }),
    [
      executionCollapseRowsIds,
      onCollapse,
      props.currentAct,
      executionCollapseRowsIds,
      handleExecutionExpandedHeadersIds
    ]
  );

  const download = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const key = e.currentTarget.dataset?.key;
      const getKS = key === 'ks-2' ? getKS2V2 : getKS6V2;
      if (props.calcID && props.currentAct && key && props.currentAct.id) {
        enqueueSnackbar(
          'Ожидайте загрузку архива, около 2 минут. Не покидайте вкладку.',
          {
            autoHideDuration: 10000,
            variant: 'info'
          }
        );
        getKS({ calcID: Number(props.calcID), actID: props.currentAct.id })
          .then((response) => {
            const res = { data: response.data! as KSResponse };
            enqueueSnackbar('Загрузка завершена', {
              autoHideDuration: 2000,
              variant: 'success'
            });
            const sliceSize = 1024;
            const byteCharacters = atob(res?.data?.archiveBase64 as string);
            const byteArrays = [];

            for (
              let offset = 0;
              offset < byteCharacters.length;
              offset += sliceSize
            ) {
              const slice = byteCharacters.slice(offset, offset + sliceSize);
              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }
            const blob = new Blob(byteArrays, { type: 'application/zip' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${key === 'ks-2' ? 'КС-2' : 'КС-6A'}.zip`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch((er) => {
            console.log(er);
            enqueueSnackbar(
              'При загрузке архива произошла ошибка, попробуйте позже',
              {
                autoHideDuration: 2000,
                variant: 'error'
              }
            );
          });
      }
    },
    [props.calcID, props.currentAct, getKS2V2, getKS6V2]
  );

  useEffect(() => {
    const isPageChanged =
      executionTableState.page !==
      (originalArgs?.page ?? executionTableState.originalArgs?.page);
    const isDepthChanged = executionTableState.depth !== checkedDepth;

    if (
      [
        !isPageChanged,
        !isDepthChanged,
        executionTableState.isFirstDataFetched
      ].every(Boolean) &&
      !!executionTableState.data?.data.length
    )
      return;

    getExecutionCalculationRim({
      calcID: props.calcID,
      page: isDepthChanged ? 0 : executionTableState.page,
      depth: checkedDepth,
      limit: LIMIT
    });
  }, [
    executionTableState.page,
    checkedDepth,
    executionTableState.isFirstDataFetched
  ]);

  useEffect(() => {
    if (gridRef && gridRef?.api) {
      gridRef.api.refreshCells({ force: true });
      // if ag grid not rendered it my cause error
      try {
        gridRef.api.refreshHeader();
      } catch (error) {
        console.warn('Failed to refresh grid header:', error);
      }
    }
  }, [contextTable]);

  useEffect(() => {
    if (gridRef && gridRef.api) {
      gridRef.api.setIsExternalFilterPresent(() => true);
      gridRef.api.onFilterChanged();
      gridRef.api.refreshCells({ force: true });
    }
  }, [doesExternalFilterPass]);

  useEffect(() => {
    if (checkedDepth === 1 && dataCalculationComplicated?.data?.length) {
      return;
    }
    if (checkedDepth && dataCalculationComplicated?.data?.length) {
      const newCollapseRowsIds: Set<number> = new Set(executionCollapseRowsIds);
      dataCalculationComplicated.data.forEach((item) => {
        if (checkedDepth > item.lvl && typeof item.id === 'number') {

          newCollapseRowsIds.add(item.id);
        } else if (checkedDepth <= item.lvl && typeof item.id === 'number') {
          newCollapseRowsIds.delete(item.id);
        }
      });
      dispatch(setExecutionCollapseRowsIds(newCollapseRowsIds));
    }
  }, [checkedDepth, dataCalculationComplicated]);

  useEffect(
    function () {
      if (!props.firstLoad) {
        gridRef?.api?.setIsExternalFilterPresent(() => true);
        gridRef?.api?.onFilterChanged();
      }
    },
    [checkedDepth]
  );
  useEffect(() => {
    resetColumns();
  }, [props.actList]);

  useEffect(() => {
    setMaxDepth('executedPage', 3);
  }, []);

  const handleColumnResized = useCallback(
    (event: ColumnResizedEvent) => {
      const data = agGridColumnResized(event);
      if (!data) return;

      dispatch(
        updateExecutionColumnsWidth({
          ...executionColumnsWidth,
          [data.columnId]: data.width
        })
      );
    },
    [dispatch, executionColumnsWidth]
  );

  return (
    <PageStyled style={{ height: 'calc(100% - 10px)' }}>
      <Box sx={{ background: 'white' }}>
        <SetupTable $border>
          <SetupRow>
            <SetupItem hideBefore>
              Акт:
              {!dataFirstFilterTreeLength ? (
                <StyledParametersButton
                  onClick={() => setCreateModal(true)}
                  fullWidth
                  sx={{ fontSize: '14px' }}
                  variant={'outlined'}>
                  Создать новый акт
                </StyledParametersButton>
              ) : (
                <StyledSelect
                  width={235}
                  onClear={() => {}}
                  nullableValues={[renderAct, 'Выберите диапазон']}
                  SelectProps={{
                    renderValue: () => renderAct,
                    MenuProps: {
                      sx: {
                        maxHeight: 500,
                        width: 235
                      }
                    },
                    inputProps: {
                      sx: {
                        padding: '4.5px 14px'
                      }
                    },
                    value: renderAct || 'Выберите диапазон',
                    placeholder: 'Выберите диапазон'
                  }}
                  value={renderAct || 'Выберите диапазон'}
                  placeholder={'Выберите диапазон'}
                  fullWidth
                  select>
                  <Stack direction="row" px={1} py={1}>
                    <StyledParametersButton
                      onClick={() => setCreateModal(true)}
                      fullWidth
                      sx={{ fontSize: '14px' }}
                      variant={'outlined'}>
                      Создать новый акт
                    </StyledParametersButton>
                  </Stack>
                  <Divider />
                  <MenuItem
                    style={{ height: 0, overflow: 'hidden', padding: 0 }}
                    selected
                    hidden={true}
                    value={'Выберите диапазон'}>
                    Выберите диапазон
                  </MenuItem>
                  {(props.actList?.actList || [])?.map((item) => {
                    return (
                      <MenuItem
                        key={item.id}
                        onClick={() => {
                          if (props.currentAct?.id !== item.id) {
                            props.changeCurrentAct(item);
                          }
                        }}
                        selected={
                          formatDateExecutedTab(item) === renderAct &&
                          item.id === props.currentAct?.id
                        }
                        value={formatDateExecutedTab(item)}>
                        {formatDateExecutedTab(item)}
                      </MenuItem>
                    );
                  })}
                </StyledSelect>
              )}
            </SetupItem>
            <SetupItem>
              Экспорт:
              <StyledParametersButton
                data-key={'ks-2'}
                disabled={isFetchingKS2V2 || !props.currentAct}
                fullWidth
                sx={{ fontSize: '14px' }}
                onClick={download}
                variant={'outlined'}>
                {isFetchingKS2V2 ? <CircularProgress size={20} /> : 'КС-2'}
              </StyledParametersButton>
              <StyledParametersButton
                data-key={'ks-6'}
                onClick={download}
                disabled={isFetchingKS6V2 || !props.currentAct}
                fullWidth
                sx={{ fontSize: '14px' }}
                variant={'outlined'}>
                {isFetchingKS6V2 ? <CircularProgress size={20} /> : 'КС-6а'}
              </StyledParametersButton>
            </SetupItem>
          </SetupRow>
          <SetupRow>
            {props.currentAct && props.currentAct.status && (
              <Badge {...statusesAct[props.currentAct.status || 'NEW']} />
            )}
          </SetupRow>
        </SetupTable>
      </Box>
      {!!executionTableState.data.data.length &&
        (executionTableState.isUpdating ||
          isChildrenFetching ||
          isRowUpdating) && (
          <CircularProgress
            size={50}
            sx={{
              zIndex: 100,
              position: 'absolute',
              top: '50%',
              left: '50%',
              translate: '-50% -50%'
            }}
          />
        )}

      <WrapperAgGrid className="ag-theme-material reference-prices index-methods-calculation">
        <AgGridReact
          ref={ref}
          onGridReady={(event) => {
            dispatch(setGridRef(event));
          }}
          context={contextTable}
          defaultColDef={defaultDef}
          columnDefs={columnDefs}
          singleClickEdit
          onViewportChanged={debouncedUpdateVisibleRows}
          onColumnResized={handleColumnResized}
          onBodyScroll={debouncedUpdateVisibleRows}
          getRowClass={(props) =>
            getRowClassIndexMethodCalculation(props as any)
          }
          gridOptions={{
            ...gridOptions,
            maintainColumnOrder: true
          }}
          pinnedTopRowData={executionTableState.data?.total}
          rowData={
            isDepthChanged && (isLoading || executionTableState.isUpdating)
              ? []
              : executionTableState.data.data?.concat(limitedRows)
          }
          getRowId={(params) => (params.data.id || '').toString()}
          getRowHeight={(params) => {
            if (params.data?.type === 'limit-header') {
              return 30;
            }
            if (params.node.rowPinned === 'top') {
              return 50;
            }
            return 55;
          }}
          rowStyle={{
            padding: '0 !important'
          }}
          getRowStyle={(params) => {
            if (params.data?.type === 'limit-header') {
              return {
                'pointer-events': 'none'
              };
            }
            return;
          }}
          suppressCellFocus
          enableCellTextSelection
          ensureDomOrder
          maintainColumnOrder
          rowHeight={55}
          groupHeaderHeight={35.5}
          headerHeight={40}
          loadingOverlayComponent={Progress}
          noRowsOverlayComponent={
            executionTableState.isFirstDataFetched ? NoRows : Progress
          }
          doesExternalFilterPass={doesExternalFilterPass}
        />
        <ActDialog
          data={props.actList ?? undefined}
          update={() => {
            gridRef?.api?.showLoadingOverlay();
            dispatch(setExecutionRimTableState(new RimExecutionTableState()));
            dispatch(setExecutionCollapseRowsIds(new Set()));
            resetColumns();
          }}
          setCurrentOuter={props.changeCurrentAct}
          open={createModal}
          close={() => setCreateModal(false)}
        />
      </WrapperAgGrid>
    </PageStyled>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { appendData, getChildren, getData } from './actions';
import { IInitBimCalcState } from './bim.types';
import { ExecutionState } from '../../../../pages/Calculations/components/CalculationСomplicated/CalculationСomplicated.types';
import { RimExecutionTableState } from '../../../../pages/projects-calculation/index-method-execution';

const initialState: IInitBimCalcState = {
  list: [],
  filterList: [],
  total: [],
  loader: false,
  endFetch: false,
  page: 0,
  hiddenRows: new Set(),
  reset: false,
  collapseMode: 1,
  isNotFetched: true,
  executionHiddenRowsIds: [],
  executionTableState: new ExecutionState(),
  executionExpandedHeadersIds: new Set()
};

export const bimSliceCalculation = createSlice({
  name: 'bimSliceCalculation',
  initialState,
  reducers: {
    toggleCollapseMod(state, action: PayloadAction<number>) {
      state.collapseMode = action.payload;
      // const newSet: Set<number> = new Set();
      // switch (action.payload) {
      //   case 'collapse': {
      //     (async () => {
      //       state.list.forEach((el) => {
      //         newSet.add(el.id);
      //       });
      //       state.hiddenRows = newSet;
      //     })();
      //     break;
      //   }
      //   case 'unCollapse': {
      //     (async () => {
      //       state.hiddenRows = new Set();
      //     })();
      //   }
      // }
    },
    reset(state) {
      state.reset = !state.reset;
      state.page = 0;
      state.loader = false;
      state.endFetch = false;
      state.list = [];
      state.total = [];
    },
    resetBimExecution(state) {
      state.executionTableState = new ExecutionState();
      state.executionHiddenRowsIds = [];
      state.executionExpandedHeadersIds = new Set();
      state.executionTableState.resetCounter =
        state.executionTableState.resetCounter + 1;
    },
    setHiddenRows(state, action: PayloadAction<Set<number>>) {
      state.hiddenRows = action.payload;
    },
    initList(
      state,
      action: PayloadAction<{
        list: IInitBimCalcState['list'];
        total: IInitBimCalcState['total'];
      }>
    ) {
      console.log('init', action.payload);
      state.list = action.payload.list;
      state.page = 0;
      state.total = action.payload.total;
      state.endFetch = false;
    },
    togglePage(state, action: PayloadAction<number | undefined>) {
      state.page =
        action.payload !== undefined ? action.payload : state.page + 1;
    },
    appendRows(state, action: PayloadAction<IInitBimCalcState['list']>) {
      console.log('append', action.payload);
      state.list = state.list.concat(action.payload);
    },
    addByID(
      state,
      action: PayloadAction<{ id: number; data: IInitBimCalcState['list'] }>
    ) {
      const targetIndex = state.list.findIndex(
        (el) => el.id === action.payload.id
      );
      if (targetIndex >= 0) {
        state.list.splice(targetIndex + 1, 0, ...action.payload.data);
      }
    },
    removeByID(state, action: PayloadAction<number>) {
      const ids: number[] = [action.payload];
      function flatFilter(
        children: IInitBimCalcState['list'],
        targetLevel: number
      ) {
        children.forEach((el) => {
          if (ids.includes(el.parentID!)) {
            if (el.children && el.level! > targetLevel) {
              ids.concat(el.children.map((item) => item.id));
              flatFilter(el.children!, targetLevel);
            }
          }
        });
      }
      const targetIndex = state.list.findIndex(
        (el) => el.id === action.payload
      );
      if (targetIndex >= 0) {
        const target = state.list[targetIndex];
        flatFilter(target.children, target.level!);
        state.list = state.list.filter((el) => !ids.includes(el.parentID!));
      }
    },
    filterByID(state, action: PayloadAction<Set<number>>) {
      state.list = state.list.filter((el) => !action.payload.has(el.id));
    },
    toggleLoader(state, action: PayloadAction<boolean | undefined>) {
      console.log('loader send');
      if (action.payload !== undefined) {
        state.loader = action.payload;
      } else {
        state.loader = !state.loader;
      }
    },
    clear(state) {
      console.log('clear state bim calc');
      state.reset = !state.reset;
      state.page = 0;
      state.loader = false;
      state.endFetch = false;
      state.list = [];
      state.total = [];
      state.executionHiddenRowsIds = [];
      state.executionTableState = new ExecutionState();
      state.executionExpandedHeadersIds = new Set();
    },
    toggleEndFetch(state, action: PayloadAction<boolean | undefined>) {
      state.endFetch =
        action.payload !== undefined ? action.payload : !state.endFetch;
    },
    toggleIsNotFetched(state, action: PayloadAction<boolean>) {
      state.isNotFetched = action.payload;
    },
    updateData(state, action: PayloadAction<IInitBimCalcState['list']>) {
      console.log('updateData', action.payload);
      action.payload.forEach((el) => {
        const index = state.list.findIndex((item) => item.id === el.id);
        if (index >= 0) {
          state.list[index] = el;
        }
      });
    },
    updateExecutionHiddenRowsIds(
      state,
      action: PayloadAction<IInitBimCalcState['executionHiddenRowsIds']>
    ) {
      state.executionHiddenRowsIds = action.payload;
    },
    updateExecutionTableState(
      state,
      action: PayloadAction<IInitBimCalcState['executionTableState']>
    ) {
      state.executionTableState = action.payload;
    },
    updateExecutionExpandedHeadersIds(
      state,
      action: PayloadAction<IInitBimCalcState['executionExpandedHeadersIds']>
    ) {
      state.executionExpandedHeadersIds = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(appendData.fulfilled, (state, action) => {
      if (action.payload === null) return;
      state.list = state.list.concat(action.payload.data);
      state.total = [action.payload.total];
    });
    builder.addCase(getData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getData.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(getData.fulfilled, (state, action) => {
      state.loader = false;
      if (action.payload === null) return;
      state.loader = false;
      state.list = action.payload.data;
      state.total = [{ ...action.payload.total, id: 0.25 }];
    });
    builder.addCase(getChildren.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getChildren.rejected, (state) => {
      state.loader = false;
    });
    builder.addCase(getChildren.fulfilled, (state, action) => {
      state.loader = false;
      if (action.payload === null) return;
      const { children, parentID, isDelete, fromIndex } = action.payload;
      state.loader = false;
      const copyList = [...state.list];
      if (isDelete) {
        copyList.splice(fromIndex, children.length);
      } else {
        copyList.splice(fromIndex, 0, ...children);
      }
      state.list = copyList;
      // state.total = [action.payload.total];
    });
  }
});
export const getBimCalcState = (state: RootState) =>
  state.calculations.bim.calc;
export const {
  reset,
  togglePage,
  clear,
  initList,
  removeByID,
  appendRows,
  addByID,
  resetBimExecution,
  toggleLoader,
  toggleEndFetch,
  toggleIsNotFetched,
  setHiddenRows,
  toggleCollapseMod,
  updateData,
  filterByID,
  updateExecutionHiddenRowsIds,
  updateExecutionTableState,
  updateExecutionExpandedHeadersIds
} = bimSliceCalculation.actions;
export default bimSliceCalculation.reducer;
